import React, { useState, useEffect, Fragment } from "react";
import { addExistingVaccinelist, getdata, getParentVaccineName, deleteVaccinedetails } from "./store/index";
import { useDispatch } from "react-redux";
import { Modal, Form, Nav, Row, Col, Button } from "react-bootstrap";
import { useTranslation } from 'react-i18next'
import Breadcrumbs from '../../breadcrumbs'
import webservice from './../../../services/webservice';
import { v4 as uuidv4 } from 'uuid';
import { Button as Button1 } from "reactstrap";
import img from '../../../images/no-data.svg'
import swal from "sweetalert";

const Vaccination = () => {
  const [allData, setAllData] = useState([]);
  const [ParentVaccineName, setParentVaccineName] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const lang = localStorage.getItem('lang');
  const [errors, setErrors] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [activeTab, setActiveTab] = useState('addNew');
  const [newVaccine, setNewVaccine] = useState({
    vaccine_name: '',
    parent_vaccine_name: '',
    reason: '',
    content: '',
    year: '',
    year_start: '',
    year_end: '',
    img: '',
    imgName: '',
    language: '',
  });

  useEffect(() => {
    const fetchData = async () => {
      const lang = 'en'
      const response = await dispatch(getParentVaccineName(lang));

      if (response.payload && response.payload.data) {
        setParentVaccineName(response.payload.data);
        setIsLoading(false);
      }
    };

    fetchData();

  }, [dispatch]);

  useEffect(() => {
    const fetchData = async () => {
      const response = await dispatch(getdata(lang));

      if (response.payload && response.payload.data) {
        setAllData(response.payload.data);
        setIsLoading(false);
      }
    };
    if (lang !== '') {
      fetchData();
    }
  }, [dispatch, lang]);


  const handleEdit = (item) => {
    setErrors({});
    setNewVaccine({
      _id: item._id || '',
      RefId: item.RefId || '',
      vaccine_name: item.vaccine_name || '',
      reason: item.reason || '',
      content: item.content || '',
      img: item.img || '',
      year: item.year || '',
      year_start: item.year_start || '',
      year_end: item.year_end || ''
    });
    setShowEditModal(true);
  };


  const handleDelete = (item) => {
    const _id = item._id

    dispatch(deleteVaccinedetails({ lang, _id })).then((res) => {
      setAllData(res.payload.data)
    })

  };

  const handleInputSelectChange = (e) => {
    const { name, value } = e.target;
    const selectedVaccine = ParentVaccineName.find((item) => item.vaccine_name === value);

    if (selectedVaccine) {
      setNewVaccine((prevState) => ({
        ...prevState,
        [name]: value,
        RefId: selectedVaccine.RefId,
        img: selectedVaccine.img,
      }));
    }

    setErrors((prevErrors) => ({ ...prevErrors, [name]: '' }));
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewVaccine((prevState) => ({
      ...prevState,
      [name]: value,
    }));
    setErrors((prevErrors) => ({ ...prevErrors, [name]: '' }));
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    let error = '';

    if (!file) {
      error = t('Image upload is required.');
    } else if (!file.type.startsWith('image/')) {
      error = t('Only image files are allowed.');
    } else if (file.size > 12500) {
      error = t('Image file size must be less than 100KB.');
    } else {
      const reader = new FileReader();
      reader.onloadend = () => {

        setNewVaccine((prevState) => ({
          ...prevState,
          img: reader.result,
          imgName: file.name,
        }));
      };
      reader.readAsDataURL(file);
    }
    setErrors((prevErrors) => ({ ...prevErrors, img: error }));
  };

  const handleCloseModal = () => {
    setNewVaccine({
      vaccine_name: '',
      parent_vaccine_name: '',
      reason: '',
      content: '',
      year: '',
      year_start: '',
      year_end: '',
      img: '',
      imgName: '',
      language: '',
    });
    setActiveTab('addNew');
    setShowModal(false);
    setShowEditModal(false)
    setErrors({});
  };

  const validateForm = () => {
    const newErrors = {};
    if (activeTab === 'addNew' && !newVaccine.vaccine_name) { newErrors.vaccine_name = t('Vaccine Name is required.'); }
    if (!newVaccine.reason) { newErrors.reason = t('Reason is required.'); }
    if (!newVaccine.content) { newErrors.content = t('Content is required.'); }
    if (!newVaccine.year) { newErrors.year = t('Year is required.'); }
    if (!newVaccine.year_start) { newErrors.year_start = t('Year Start is required.'); }
    if (!newVaccine.year_end) { newErrors.year_end = t('Year End is required.'); }
    if (activeTab === 'addNew' && !newVaccine.img) { newErrors.img = t('Image is required.'); }
    if (activeTab === 'addExisting' && !newVaccine.parent_vaccine_name) { newErrors.parent_vaccine_name = t('Parent Vaccine Name is required.'); }
    if (activeTab === 'addExisting' && !newVaccine.language) { newErrors.language = t('Language is required.'); }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };


  const dataURLtoBlob = (dataurl) => {
    let arr = dataurl.split(','), mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new Blob([u8arr], { type: mime });
  };

  const handleAddVaccine = async () => {
    if (validateForm()) {
      if (activeTab === 'addNew') {
        const formData = new FormData();
        formData.append('lang', 'en');
        formData.append('RefId', uuidv4());
        formData.append('vaccine_name', newVaccine.vaccine_name);
        formData.append('reason', newVaccine.reason);
        formData.append('content', newVaccine.content);
        formData.append('year', newVaccine.year);
        formData.append('year_start', newVaccine.year_start);
        formData.append('year_end', newVaccine.year_end);

        if (newVaccine.img) {
          const imageBlob = dataURLtoBlob(newVaccine.img);
          formData.append('img', imageBlob, newVaccine.imgName);
        }
        try {
          const response = await fetch(webservice + 'api/addVaccinelist', {
            body: formData,
            method: 'POST',
          });

          const data = await response.json();
          setAllData(data.data);
          handleCloseModal();
        } catch (error) {
          console.error("Error sending Vaccine data to backend:", error);
        }
      } else if (activeTab === 'addExisting') {

        const Data = {
          sessionlang:lang,
          language: newVaccine.language,
          vaccine_name: newVaccine.vaccine_name,
          reason: newVaccine.reason,
          content: newVaccine.content,
          year: newVaccine.year,
          year_start: newVaccine.year_start,
          year_end: newVaccine.year_end,
          img: newVaccine.img,
          RefId: newVaccine.RefId
        };
        dispatch(addExistingVaccinelist(Data)).then((res) => {
          if (res.payload.message === 'Vaccine with this RefId already exists') {
            swal({
              title: "Duplicate Vaccine",
              text: "This Vaccine is already exists to the Selected Language",
              icon: "warning",
              button: "OK",
            });
            
          } else {
            setAllData(res.payload.data);
            handleCloseModal();
          }
          
        })
      }
    }
  };

  const handleUpdateVaccine = async () => {

    if (validateForm()) {
      const formData = new FormData();
      formData.append('lang', lang);
      formData.append('RefId', newVaccine.RefId);
      formData.append('_id', newVaccine._id);
      formData.append('vaccine_name', newVaccine.vaccine_name);
      formData.append('reason', newVaccine.reason);
      formData.append('content', newVaccine.content);
      formData.append('year', newVaccine.year);
      formData.append('year_start', newVaccine.year_start);
      formData.append('year_end', newVaccine.year_end);


      const disallowedPrefixes = [
        'http://localhost:5000/server/vaccineimages/',
        'https://app.sanjeevaniapp.com/server/vaccineimages/'
      ];

      // Check if the img URL starts with any of the disallowed prefixes
      const isDisallowed = disallowedPrefixes.some(prefix => newVaccine.img.startsWith(prefix));

      if (!isDisallowed) {
        const imageBlob = dataURLtoBlob(newVaccine.img);
        formData.append('img', imageBlob, newVaccine.imgName);
      } else {
        formData.append('img', newVaccine.img);
      }

      try {
        const response = await fetch(webservice + 'api/updateVaccinelist', {
          body: formData,
          method: 'POST',
        });

        const data = await response.json();
        setAllData(data.data);
        handleCloseModal();
      } catch (error) {
        console.error("Error sending Vaccine data to backend:", error);
      }
    }
  };

  const currentYear = new Date().getFullYear();
  const years = Array.from({ length: 100 }, (_, index) => currentYear - index);

  const removeImage = () => {
    setNewVaccine((prevState) => ({
      ...prevState,
      img: '',
      imgName: '',
    }));
  };


  return (
    <Fragment>
      <Breadcrumbs title={t('Vaccine Name Details')} data={[{ title: t('Vaccine Name') }]} />
      <div className="d-flex justify-content-end mb-3">
        <i
          className="flaticon-381-add-3 text-primary mx-4 add-icon"
          onClick={() => {
            setShowModal(true); setNewVaccine({
              vaccine_name: '',
              parent_vaccine_name: '',
              reason: '',
              content: '',
              year: '',
              year_start: '',
              year_end: '',
              img: '',
              imgName: '',
              language: '',
            });
          }}
          style={{ cursor: 'pointer' }}
        ></i>
      </div>
      {isLoading ? (
        <div>Loading...</div>
      ) : allData.length === 0 ? (
        <div className="d-flex justify-content-center">
        <img src={img} alt="No Data" style={{  height: '50vh' }} /> 
      
      </div>
      ) : (
        <div className="row">
          {allData.map((item, index) => (
            <div key={index} className="col-md-3 mb-4">
              <div className="card shadow-sm position-relative" >
                <div className="card-header text-center">
                  <div style={{ position: 'absolute', top: '10px', right: '10px', zIndex: '2' }}>
                    <i
                      className="fas fa-edit text-primary mx-2"
                      style={{ cursor: 'pointer' }}
                      onClick={() => handleEdit(item)}
                    ></i>
                    <i
                      className="fas fa-trash text-danger"
                      style={{ cursor: 'pointer' }}
                      onClick={() => handleDelete(item)}
                    ></i>
                  </div>
                </div>
                <div className=" d-flex justify-content-center" style={{ padding: '1.5rem', fontSize: '1rem' }}>
                  <h5 className="card-title text-center" >{item.vaccine_name}</h5>
                </div>
                <div className="d-flex justify-content-center">
                  <img
                    src={item.img}
                    className="card-img-top"
                    alt={item.vaccine_name}
                    style={{ width: '150px', height: '150px', borderRadius: '5px' }}
                  />
                </div>
                <div className="card-body" style={{ padding: '0.5rem' }}>
                  <p className="card-text reason-text" style={{ fontSize: '0.85rem' }}><strong>{t('Reason')}:</strong> {item.reason}</p>
                  <p className="card-text" style={{ fontSize: '0.85rem' }}><strong>{t('Year')}:</strong> {item.year}</p>
                  <p className="card-text" style={{ fontSize: '0.85rem' }}><strong>{t('Year Start')}:</strong> {item.year_start}</p>
                  <p className="card-text" style={{ fontSize: '0.85rem' }}><strong>{t('Year End')}:</strong> {item.year_end}</p>
                </div>
              </div>
            </div>
          ))}
        </div>
      )}
      <Modal show={showModal} onHide={handleCloseModal} size="lg" scrollable={true} centered={true} backdrop="static"
      >
        <Modal.Header closeButton>
          <div className="align-items-center mb-3">
            <Modal.Title>{t(activeTab === 'addNew' ? 'Add New Vaccine Name' : 'Add Existing Vaccine Name')}</Modal.Title>
            <br />
            <Nav style={{ marginBottom: '-4.6%' }} variant="tabs" justify activeKey={activeTab} onSelect={(selectedKey) => {
              setActiveTab(selectedKey);
              setErrors({});
              setNewVaccine({
                vaccine_name: '',
                parent_vaccine_name: '',
                reason: '',
                content: '',
                year: '',
                year_start: '',
                year_end: '',
                img: '',
                imgName: '',
                language: '',
              });
            }}>
              <Nav.Item>
                <Nav.Link eventKey="addNew" className={activeTab === 'addNew' ? 'active-tab' : ''}>
                  {t('Add New')}
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="addExisting" className={activeTab === 'addExisting' ? 'active-tab' : ''}>
                  {t('Add Existing')}
                </Nav.Link>
              </Nav.Item>
            </Nav>
          </div>
        </Modal.Header>
        <Modal.Body>

          <Form>
            
            {activeTab === 'addExisting' && (
              <>
              <Row>
                <Col>
                  <Form.Group controlId="formParentVaccineName">
                    <Form.Label>{t('Parent Vaccine Name')}</Form.Label>
                    <Form.Control
                      as="select"
                      name="parent_vaccine_name"
                      value={newVaccine.parent_vaccine_name}
                      onChange={handleInputSelectChange}
                      isInvalid={!!errors.parent_vaccine_name}
                    >
                      <option value="">{t('Select Parent Vaccine')}</option>
                      {ParentVaccineName.map((item) => (
                        <option key={item.RefId} value={item.vaccine_name}>
                          {item.vaccine_name}
                        </option>
                      ))}
                    </Form.Control>
                    {activeTab === 'addExisting' && errors.parent_vaccine_name &&
                      <Form.Control.Feedback type="invalid">{errors.parent_vaccine_name}</Form.Control.Feedback>}
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group controlId="language">
                    <Form.Label>{t('Language')}</Form.Label>
                    <Form.Control
                      as="select"
                      name="language"
                      value={newVaccine.language}
                      onChange={handleInputChange}
                      isInvalid={!!errors.language}
                    >
                      <option value="">{t('Select Language')}</option>
                      <option value="ta">Tamil</option>
                      <option value="te">Telugu</option>
                      <option value="kn">Kannada</option>
                      <option value="ml">Malayalam</option>
                      <option value="hi">Hindi</option>
                    </Form.Control>
                    {activeTab === 'addExisting' && errors.language &&
                      <Form.Control.Feedback type="invalid">{errors.language}</Form.Control.Feedback>}
                  </Form.Group>
                </Col>
              </Row>
              <br/>
              </>
            )}
   
              <Form.Group controlId="formVaccineName">
                <Form.Label>{t('Vaccine Name')}</Form.Label>
                <Form.Control
                  type="text"
                  name="vaccine_name"
                  value={newVaccine.vaccine_name}
                  onChange={handleInputChange}
                  isInvalid={activeTab === 'addNew' && !!errors.vaccine_name}
                />
                {activeTab === 'addNew' && errors.vaccine_name &&
                  <Form.Control.Feedback type="invalid">{errors.vaccine_name}</Form.Control.Feedback>}
              </Form.Group>
            
            <br />
            <Form.Group controlId="formReason">
              <Form.Label>{t('Reason')}</Form.Label>
              <Form.Control
                as="textarea"
                style={{ height: '15vh' }}
                name="reason"
                value={newVaccine.reason}
                onChange={handleInputChange}
                isInvalid={!!errors.reason}
              />
              <Form.Control.Feedback type="invalid">{errors.reason}</Form.Control.Feedback>
            </Form.Group>
            <br />
            <Form.Group controlId="formContent">
              <Form.Label>{t('Content')}</Form.Label>
              <Form.Control
                as="textarea"
                style={{ height: '30vh' }}
                name="content"
                value={newVaccine.content}
                onChange={handleInputChange}
                isInvalid={!!errors.content}
              />
              <Form.Control.Feedback type="invalid">{errors.content}</Form.Control.Feedback>
            </Form.Group>
            {activeTab === 'addNew' && (
              <>
                <br />
                <Form.Group controlId="formImg">
                  <Row>
                    <Form.Label>{t('Image Upload')}</Form.Label>
                    {newVaccine.img === '' && (<>
                      <Col>
                        <Form.Control
                          type="file"
                          name="img"
                          accept="image/*"
                          onChange={handleImageChange}
                          isInvalid={activeTab === 'addNew' && !!errors.img}
                        />
                        {activeTab === 'addNew' && errors.img && <Form.Control.Feedback type="invalid">{errors.img}</Form.Control.Feedback>}
                      </Col></>)}
                    <Col>
                      {newVaccine.img && (
                        <div style={{ position: 'relative', marginTop: '10px', marginBottom: '10px' }}>
                          <img
                            src={newVaccine.img}
                            alt="Preview"
                            style={{ width: '30%', marginTop: '20px', marginBottom: '20px', borderRadius: '5px' }}
                          />
                          <Button1 close onClick={() => removeImage()} />
                        </div>
                      )}
                    </Col>
                  </Row>
                </Form.Group>
              </>
            )}
            <br />
            <Form.Group controlId="formYear">
              <Form.Label>{t('Year')}</Form.Label>
              <Form.Control
                type="number"
                name="year"
                value={newVaccine.year}
                onChange={handleInputChange}
                isInvalid={!!errors.year}
              />
              <Form.Control.Feedback type="invalid">{errors.year}</Form.Control.Feedback>
            </Form.Group>
            <br />
            <Row>
              <Col>
                <Form.Group controlId="formYearStart">
                  <Form.Label>{t('Year Start')}</Form.Label>
                  <Form.Control
                    as="select"
                    name="year_start"
                    value={newVaccine.year_start}
                    onChange={handleInputChange}
                    isInvalid={!!errors.year_start}
                  >
                    <option value="">Select Year</option>
                    {years.map((year) => (
                      <option key={year} value={year}>{year}</option>
                    ))}
                  </Form.Control>
                  <Form.Control.Feedback type="invalid">{errors.year_start}</Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col>
                <Form.Group controlId="formYearEnd">
                  <Form.Label>{t('Year End')}</Form.Label>
                  <Form.Control
                    as="select"
                    name="year_end"
                    value={newVaccine.year_end}
                    onChange={handleInputChange}
                    isInvalid={!!errors.year_end}
                  >
                    <option value="">Select Year</option>
                    {newVaccine.year_start && years.filter(year => year >= newVaccine.year_start).map((year) => (
                      <option key={year} value={year}>{year}</option>
                    ))}
                  </Form.Control>
                  <Form.Control.Feedback type="invalid">{errors.year_end}</Form.Control.Feedback>
                </Form.Group>
              </Col>
            </Row>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant='outline-danger' onClick={handleCloseModal}>
            {t('Close')}
          </Button>
          <Button variant='outline-success' onClick={handleAddVaccine}>
            {t('Add Vaccine')}
          </Button>
        </Modal.Footer>
      </Modal>

      {/* ------------------------------------Edit Model Here------------------------------------------- */}

      <Modal show={showEditModal} onHide={() => { setShowEditModal(false) }} size="lg" scrollable={true} centered={true} backdrop="static"
      >
        <Modal.Header closeButton>
          <Modal.Title>{t('Update Vaccine Name')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="formVaccineName">
              <Form.Label>{t('Vaccine Name')}</Form.Label>
              <Form.Control
                type="text"
                name="vaccine_name"
                value={newVaccine.vaccine_name}
                onChange={handleInputChange}
                isInvalid={activeTab === 'addNew' && !!errors.vaccine_name}
              />
              {errors.vaccine_name &&
                <Form.Control.Feedback type="invalid">{errors.vaccine_name}</Form.Control.Feedback>}
            </Form.Group>
            <br/>
            <Form.Group controlId="formReason">
              <Form.Label>{t('Reason')}</Form.Label>
              <Form.Control
                as="textarea"
                style={{ height: '15vh' }}
                name="reason"
                value={newVaccine.reason}
                onChange={handleInputChange}
                isInvalid={!!errors.reason}
              />
              <Form.Control.Feedback type="invalid">{errors.reason}</Form.Control.Feedback>
            </Form.Group>
            <br/>
            <Form.Group controlId="formContent">
              <Form.Label>{t('Content')}</Form.Label>
              <Form.Control
                as="textarea"
                style={{ height: '30vh' }}
                name="content"
                value={newVaccine.content}
                onChange={handleInputChange}
                isInvalid={!!errors.content}
              />
              <Form.Control.Feedback type="invalid">{errors.content}</Form.Control.Feedback>
            </Form.Group>
            <br/>
            <Form.Group controlId="formImg">
              <Row>
                <Form.Label>{t('Image Upload')}</Form.Label>
                {newVaccine.img === '' && (<>
                  <Col>
                    <Form.Control
                      type="file"
                      name="img"
                      accept="image/*"
                      onChange={handleImageChange}
                      isInvalid={!!errors.img}
                      label={newVaccine.imgName || 'Choose File'}
                    />
                    {errors.img && <Form.Control.Feedback type="invalid">{errors.img}</Form.Control.Feedback>}
                  </Col></>)}
                <Col>
                  {newVaccine.img && (
                    <div style={{ position: 'relative', marginTop: '10px', marginBottom: '10px' }}>
                      <img
                        src={newVaccine.img}
                        alt="Preview"
                        style={{ width: '30%', marginTop: '20px', marginBottom: '20px', borderRadius: '5px' }}
                      />
                      <Button1 close onClick={() => removeImage()} />
                    </div>
                  )}
                </Col>
              </Row>
            </Form.Group>
            <br/>

            <Form.Group controlId="formYear">
              <Form.Label>{t('Year')}</Form.Label>
              <Form.Control
                type="number"
                name="year"
                value={newVaccine.year}
                onChange={handleInputChange}
                isInvalid={!!errors.year}
              />
              <Form.Control.Feedback type="invalid">{errors.year}</Form.Control.Feedback>
            </Form.Group>
            <br/>

            <Row>
              <Col>
                <Form.Group controlId="formYearStart">
                  <Form.Label>{t('Year Start')}</Form.Label>
                  <Form.Control
                    as="select"
                    name="year_start"
                    value={newVaccine.year_start}
                    onChange={handleInputChange}
                    isInvalid={!!errors.year_start}
                  >
                    <option value="">Select Year</option>
                    {years.map((year) => (
                      <option key={year} value={year}>{year}</option>
                    ))}
                  </Form.Control>
                  <Form.Control.Feedback type="invalid">{errors.year_start}</Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col>
                <Form.Group controlId="formYearEnd">
                  <Form.Label>{t('Year End')}</Form.Label>
                  <Form.Control
                    as="select"
                    name="year_end"
                    value={newVaccine.year_end}
                    onChange={handleInputChange}
                    isInvalid={!!errors.year_end}
                  >
                    <option value="">Select Year</option>
                    {newVaccine.year_start && years.filter(year => year >= newVaccine.year_start).map((year) => (
                      <option key={year} value={year}>{year}</option>
                    ))}
                  </Form.Control>
                  <Form.Control.Feedback type="invalid">{errors.year_end}</Form.Control.Feedback>
                </Form.Group>
              </Col>
            </Row>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant='outline-danger' onClick={() => setShowEditModal(false)}>
            {t('Close')}
          </Button>
          <Button variant='outline-success' onClick={handleUpdateVaccine}>
            {t('Update Vaccine')}
          </Button>
        </Modal.Footer>
      </Modal>
    </Fragment>
  );
};

export default Vaccination;
