import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { bloodreceiverdatas, getAcceptedBloodPost, getRequestedBloodDonor, updateCancelAndRemove, userAccesptPost } from "./store";
import { AesDecrypt } from "../../../services/crypto";
import { useDispatch } from "react-redux";
import { Button, Card, Label } from "reactstrap";
import { t } from "i18next";
import BreadCrumbs from "../../breadcrumbs";
import Swal from "sweetalert2";
const userId = localStorage.id


const AcceptedList = () => {
    const { id } = useParams();

    const [acceptedBloodPost, setacceptedBloodPost] = useState([]);
    const dispatch = useDispatch();
    const [showMobileFor, setShowMobileFor] = useState(null);

    useEffect(() => {
        dispatch(getAcceptedBloodPost(userId))
            .then((response) => {
                if (response.payload.response === 'S') {
                    try {
                        const decryptedData = AesDecrypt(response.payload.data);
                        if (Array.isArray(decryptedData)) {
                            setacceptedBloodPost(decryptedData);
                        } else {
                            setacceptedBloodPost([]);
                        }
                    } catch (error) {
                        console.error('Error decrypting data:', error);
                        setacceptedBloodPost([]);
                    }
                } else {
                    setacceptedBloodPost([]);
                }

            })
            .catch((error) => {
                console.error('Error fetching accepted blood posts:', error);
                setacceptedBloodPost([]);
            });
    }, [dispatch]);



    const handleWillingToDonate = async (receiver) => {
        try {

            setShowMobileFor(receiver._id);
            const response = await dispatch(userAccesptPost(receiver))
            const decryptedData = response.payload.response
            if (decryptedData === 'S') {
                dispatch(getAcceptedBloodPost(userId)).then((response) => {
                    if (response.payload.response === 'S') {
                        const decryptedData = AesDecrypt(response.payload.data);
                        if (Array.isArray(decryptedData)) {
                            setacceptedBloodPost(decryptedData);
                        } else {
                            setacceptedBloodPost([]);
                        }
                    } else {
                        setacceptedBloodPost([]);
                    }

                });
                if (!receiver.acceptStatus) {
                    Swal.fire({
                        title: 'Success',
                        text: 'You have accepted to donate.',
                        icon: 'success',
                        confirmButtonText: 'OK',
                    });
                } else {
                    setShowMobileFor(null);
                    Swal.fire({
                        title: 'Canceled',
                        text: 'You have canceled the donation.',
                        icon: 'info',
                        confirmButtonText: 'OK',
                    });
                }
            } else {
                setacceptedBloodPost([]);
            }
            return;

        } catch (error) {
            console.error('Error fetching isDonor:', error);
            Swal.fire({
                title: "Error",
                text: "Error while accepting status. Please try again later.",
                icon: "error",
                confirmButtonText: "OK",
            });
        }
    };



    return (
        <>
            <div>
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Link to="/blood">
                        <i style={{ color: '#007bff', fontSize: '32px', marginBottom: '2%' }} className="fas fa-arrow-alt-circle-left"></i>
                    </Link>
                </div>
                <div>
                    <BreadCrumbs title={t('You Accepted these donors')} data={[{ title: t('Blood Request') }]} />
                </div>
            </div>
            <div className="card-container">
                {Array.isArray(acceptedBloodPost) && acceptedBloodPost.length === 0 ? (
                    <h3 style={{ textAlign: 'center', marginTop: '10%', color: 'grey' }}>No blood request post available</h3>
                ) : (
                    Array.isArray(acceptedBloodPost) && acceptedBloodPost.map((receiver) => (
                        <Card key={receiver._id} className="card-custom">
                            <div className="card-header-custom">
                                <h2>{receiver.name} needs blood</h2>
                                <span className="blood-group-blood">  {receiver.bloodGroup || "All Blood Types"}</span>
                            </div>
                            <div className="card-body-custom">
                                <p>
                                    <strong>Mobile :</strong> {receiver.acceptStatus ? receiver.mobile : '**********'}
                                </p>
                                {/* <p>Mobile: {receiver.mobile}</p> */}
                                {/* <p> <strong>Mobile :</strong> {'**********'}</p> */}
                                <p> <strong>hospitalName : </strong> {receiver.hospitalName || 'Not Mentioned'}</p>
                                <p> <strong>Taluk : </strong> {receiver.sub_district || 'Not Mentioned'}</p>
                                <p> <strong>District : </strong> {receiver.district || 'Not Mentioned'}</p>
                                <p> <strong>State : </strong> {receiver.state || 'Not Mentioned'}</p>
                                <p> <strong>hospital Address : </strong> {receiver.address || 'Not Mentioned'}</p>
                            </div>
                            <div className="card-footer-custom">
                                Posted on: {new Date(receiver.createdDate).toLocaleDateString()}
                                <Button
                                    className={`donate-button ${receiver.acceptStatus ? 'cancel-button' : ''}`}
                                    onClick={() => handleWillingToDonate(receiver)}
                                >
                                    {receiver.acceptStatus ? 'Cancel' : 'Accept'}
                                </Button>
                            </div>
                        </Card>
                    ))
                )}

            </div>
        </>
    );
}

export default AcceptedList;
