import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import webservice from '../../../../services/webservice';
import { AesDecrypt, AesEncrypt } from '../../../../services/crypto';


export const getAllData = createAsyncThunk('PrescriptionListing/getAllData', async () => {
    const userById = localStorage.userDetails;
    const userDetails = JSON.parse(userById); // Parse the JSON string to convert it to an object
    let userId = "";

    if (userDetails && userDetails._id) {
        userId = userDetails._id;
    } else if (userDetails && userDetails.data && userDetails.data._id) {
        userId = userDetails.data._id;
    } else {
        console.error("User ID is not available.");
        return;
    }

    try {
        const postData1 = {
            staticKey1: 'staticValue1',
            userId: userId
        };
        const postData = AesEncrypt(postData1)
        const response = await fetch(webservice + 'api/getMedicalRecords', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json; charset=UTF-8', // Fix content-type header
            },
            body: JSON.stringify({ encryptedData: postData }) // Include the static data in the body
        });

        const responseData = await response.json();
        if (responseData.response === 'S') {
            const decryptedData = AesDecrypt(responseData.data)
            return decryptedData;

        }

    } catch (error) {
        console.error('Error:', error);
        throw error;
    }
}
);
export const getselectedData = createAsyncThunk('PrescriptionListing/getselectedData', async (data) => {

    const encryptedData = AesEncrypt(data)

    try {
        const response = await fetch(webservice + 'api/getSelectedMedicalRecords', {

            method: 'POST',
            body: JSON.stringify({ encryptedData }), // Pass the entire data object directly
            headers: {
                'Content-type': 'application/json; charset=UTF-8',
            }
        });
        const responseData = await response.json();

        // if (responseData.response === 'S') {
        //     const decryptedData = AesDecrypt(responseData.data)
        //     return decryptedData;
        // }
        return responseData;

    } catch (error) {
        console.error('Error:', error);
        throw error; // Rethrow the error to be handled by Redux Toolkit
    }
}
);


export const postDoctorPrescription = createAsyncThunk('DoctorPrescription/postDoctorPrescription',
    async (formData) => { // Accept FormData object
        const userById = localStorage.userDetails;
        const userDetails = JSON.parse(userById); // Parse the JSON string to convert it to an object
        let userId = ""; // Initialize userId variable
        // Check if userDetails._id is defined
        if (userDetails && userDetails._id) {
            userId = userDetails._id; // Store the value of _id in userId
        } else if (userDetails && userDetails.data && userDetails.data._id) {
            userId = userDetails.data._id; // Store the value of _id from data in userId
        } else {
            console.error("User ID is not available."); // Log an error if userId is not available
            return; // Exit the function early
        }
        try {
            const response = await fetch(webservice + 'api/uploadMedicalRecord', {
                method: 'POST',
                body: formData, userId // Pass the FormData directly
                // No need to set content-type, FormData will set the appropriate headers
            });
            if (!response.ok) {
                throw new Error('Error uploading file');
            }
            const responseData = await response.json();
            return responseData;
        } catch (error) {
            console.error('Error:', error);
            throw error;
        }
    }
);

export const updateDoctorPrescription = createAsyncThunk('bloodpressure/updateDoctorPrescription', async ({ data }) => {
    const EncryptedData = AesEncrypt(data)
    try {
        const response = await fetch(webservice + 'api/updateMedicalRecord', {
            method: 'POST',
            headers: {
                'Content-type': 'application/json; charset=UTF-8',
            },

            body: JSON.stringify({ EncryptedData: EncryptedData }),
        });
        const responseData = await response.json();
        return responseData;
    } catch (error) {
        console.error('Error:', error);
        throw error;
    }
});


export const deleteDoctorPrescription = createAsyncThunk('bloodpressure/deleteDoctorPrescription', async (id) => { // Modified to accept only ID
    const encryptedData = AesEncrypt(id)
    try {
        const response = await fetch(webservice + 'api/deleteMedicalRecord', {
            method: 'POST',
            headers: {
                'Content-type': 'application/json; charset=UTF-8',
            },
            body: JSON.stringify({ id: encryptedData }), // Pass only the ID in the request body
        });
        const responseData = await response.json();
        return responseData;
    } catch (error) {
        console.error('Error:', error);
        throw error;
    }
});

// Define initial state
const initialState = {
    data: [],
    loading: false,
    error: null,
};

// Define Redux slice
const doctorPrescriptionSlice = createSlice({
    name: 'DoctorPrescription',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getAllData.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(getAllData.fulfilled, (state, action) => {
                state.loading = false;
                state.data = action.payload;
            })
            .addCase(getAllData.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            })
            .addCase(postDoctorPrescription.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(postDoctorPrescription.fulfilled, (state, action) => {
                state.loading = false;
                // Handle response data if needed
            })
            .addCase(postDoctorPrescription.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            });
    },
});

// Export reducer and actions
export default doctorPrescriptionSlice.reducer;