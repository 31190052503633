import axios from 'axios';
import webservice from '../../../../services/webservice';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

export const getlanguage = createAsyncThunk('sidemenu/getAdminLanguage', async (data) => {

    const response = await fetch(webservice + 'api/getAdminLanguage', {
        method: 'POST',
        body: JSON.stringify({ data }),
        headers: {
            'Content-type': 'application/json; charset=UTF-8',
        }
    });
    // Dispatch an action to get all data after update
    const responseData = await response.json();
    return responseData; // Return the updated data
});

export const getfruitdata = createAsyncThunk('UserType/getfruitdata', async (lang) => {
    
    try {
        const response = await fetch(webservice + 'api/fruits', {
            method: 'POST', // Change method to GET
            body: JSON.stringify({ lang }),
            headers: {
                'Content-type': 'application/json; charset=UTF-8',
            }
        });
        const responseData = await response.json();
        return responseData;
    } catch (error) {
        console.error('Error:', error);
        throw error; // Rethrow the error to be handled by Redux Toolkit
    }
});



export const postfruitdata = createAsyncThunk('UserType/postfruitdata', async (formData) => {
    try {
        const response = await fetch(webservice + 'api/postfruits', {
            method: 'POST',
            body: formData, // Send the FormData object directly
        });
        const responseData = await response.json();
        return responseData;
    } catch (error) {
        console.error('Error:', error);
        throw error; // Rethrow the error to be handled by Redux Toolkit
    }
});
 

export const Deletefruitdata = createAsyncThunk('UserType/postfruitdata', async (data) => {
    
    try {
        const response = await fetch(webservice + 'api/deletefruits', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',  // Set content type to JSON
            },
            body: JSON.stringify(data), // Convert the JavaScript object to JSON
        });
        const responseData = await response.json();
        return responseData;
    } catch (error) {
        console.error('Error:', error);
        throw error; // Rethrow the error to be handled by Redux Toolkit
    }
});


export const Editfruitdata = createAsyncThunk('UserType/postfruitdata', async (data) => {
    
    try {
        const response = await fetch(webservice + 'api/fruitsDetails', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',  // Set content type to JSON
            },
            body: JSON.stringify(data), // Convert the JavaScript object to JSON
        });
        const responseData = await response.json();
        return responseData;
    } catch (error) {
        console.error('Error:', error);
        throw error; // Rethrow the error to be handled by Redux Toolkit
    }
});


export const postEdidedfruitdata = createAsyncThunk('UserType/postfruitdata', async (formData) => {
    try {
        const response = await fetch(webservice + 'api/editfruits', {
            method: 'POST',
            body: formData, // Send the FormData object directly
        });
        const responseData = await response.json();
        return responseData;
    } catch (error) {
        console.error('Error:', error);
        throw error; // Rethrow the error to be handled by Redux Toolkit
    }
});






 
export const fruitsdetails = createAsyncThunk('UserType/fruitsdetails', async (data) => {
    try {
        const response = await fetch(webservice + 'api/fruitsDetails', {
            method: 'POST',
            body: JSON.stringify({ data }), // Pass the entire data object directly
            headers: {
                'Content-type': 'application/json; charset=UTF-8',
            }
        });

        const responseData = await response.json();
        return responseData;
    } catch (error) {
        console.error('Error:', error);
        throw error; // Rethrow the error to be handled by Redux Toolkit
    }
});




export const getvegdata = createAsyncThunk('UserType/getvegdata', async (lang) => {

    try {
        const response = await fetch(webservice + 'api/vegetables', {
            method: 'POST', // Change method to GET
            body: JSON.stringify({ lang }), // Pass the entire data object directly
            headers: {
                'Content-type': 'application/json; charset=UTF-8',
            }
        });

        const responseData = await response.json();
        return responseData;
    } catch (error) {
        console.error('Error:', error);
        throw error; // Rethrow the error to be handled by Redux Toolkit
    }
});



export const postvegdata = createAsyncThunk('UserType/postvegdata', async (formData) => {
    try {
        const response = await fetch(webservice + 'api/postvegdata', {
            method: 'POST',
            body: formData, // Send the FormData object directly
        });
        const responseData = await response.json();
        return responseData;
    } catch (error) {
        console.error('Error:', error);
        throw error; // Rethrow the error to be handled by Redux Toolkit
    }
});


export const postEdidedvegdata = createAsyncThunk('UserType/postEdidedvegdata', async (formData) => {
    
    try {
        const response = await fetch(webservice + 'api/postEdidedvegdata', {
            method: 'POST',
            body: formData, // Send the FormData object directly
        });
        const responseData = await response.json();
        return responseData;
    } catch (error) {
        console.error('Error:', error);
        throw error; // Rethrow the error to be handled by Redux Toolkit
    }
});
 



export const Deletevegdata = createAsyncThunk('UserType/Deletevegdata', async (data) => {
    
    try {
        const response = await fetch(webservice + 'api/Deletevegdata', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',  // Set content type to JSON
            },
            body: JSON.stringify(data), // Convert the JavaScript object to JSON
        });
        const responseData = await response.json();
        return responseData;
    } catch (error) {
        console.error('Error:', error);
        throw error; // Rethrow the error to be handled by Redux Toolkit
    }
});

export const vegdetails = createAsyncThunk('UserType/vegdetails', async (data) => {
    try {
        const response = await fetch(webservice + 'api/vegDetails', {
            method: 'POST',
            body: JSON.stringify({ data }), // Pass the entire data object directly
            headers: {
                'Content-type': 'application/json; charset=UTF-8',
            }
        });

        const responseData = await response.json();
        return responseData;
    } catch (error) {
        console.error('Error:', error);
        throw error; // Rethrow the error to be handled by Redux Toolkit
    }
});


export const getnutdata = createAsyncThunk('UserType/getnutdata', async (lang) => {
    try {
        const response = await fetch(webservice + 'api/grains', {
            method: 'POST', // Change method to GET
            body: JSON.stringify({ lang }), // Pass the entire data object directly
            headers: {
                'Content-type': 'application/json; charset=UTF-8',
            }
        });

        const responseData = await response.json();
        return responseData;
    } catch (error) {
        console.error('Error:', error);
        throw error; // Rethrow the error to be handled by Redux Toolkit
    }
});

export const postnutdata = createAsyncThunk('UserType/postnutdata', async (formData) => {
    try {
        const response = await fetch(webservice + 'api/postnutdata', {
            method: 'POST',
            body: formData, // Send the FormData object directly
        });
        const responseData = await response.json();
        return responseData;
    } catch (error) {
        console.error('Error:', error);
        throw error; // Rethrow the error to be handled by Redux Toolkit
    }
});



export const postEdidednutdata = createAsyncThunk('UserType/postEdidednutdata', async (formData) => {
    
    try {
        const response = await fetch(webservice + 'api/postEdidednutdata', {
            method: 'POST',
            body: formData, // Send the FormData object directly
        });
        const responseData = await response.json();
        return responseData;
    } catch (error) {
        console.error('Error:', error);
        throw error; // Rethrow the error to be handled by Redux Toolkit
    }
});

export const Deletenutdata = createAsyncThunk('UserType/Deletenutdata', async (data) => {
    
    try {
        const response = await fetch(webservice + 'api/Deletenutdata', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',  // Set content type to JSON
            },
            body: JSON.stringify(data), // Convert the JavaScript object to JSON
        });
        const responseData = await response.json();
        return responseData;
    } catch (error) {
        console.error('Error:', error);
        throw error; // Rethrow the error to be handled by Redux Toolkit
    }
});


export const nutsdetails = createAsyncThunk('UserType/nutsdetails', async (data) => {
    
    try {
        const response = await fetch(webservice + 'api/grainsDetails', {
            method: 'POST',
            body: JSON.stringify({ data }), // Pass the entire data object directly
            headers: {
                'Content-type': 'application/json; charset=UTF-8',
            }
        });

        const responseData = await response.json();
        return responseData;
    } catch (error) {
        console.error('Error:', error);
        throw error; // Rethrow the error to be handled by Redux Toolkit
    }
});


export const DocumentUploadSlice = createSlice({
    name: 'DocumentUpload',
    initialState: {
        data: [],
        total: 0,
        params: {},
        allData: []
    },
    reducers: {


    },
    extraReducers: builder => {
        builder.addCase(getfruitdata.fulfilled, (state, action) => {
            state.data = action.payload.data
            state.allData = action.payload.allData
            state.total = action.payload.totalPages
            state.params = action.payload.params
            state.no_Data = action.payload.no_Data
        })
    }
})