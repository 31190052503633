import React, { useState, Fragment, useEffect } from "react";
import { Link } from "react-router-dom";
import { Deletefruitdata, getfruitdata, getlanguage, postEdidedfruitdata, postfruitdata } from "./store/index";
import { useDispatch } from "react-redux";
import { useTranslation } from 'react-i18next';
import Breadcrumbs from '../../breadcrumbs';
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
  FormGroup,
  Label,
  Input
} from "reactstrap";
import Swal from "sweetalert2";

const Fruits = () => {
  const [imageData, setImageData] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [lang, setlanguage] = useState("");
  const [modal, setModal] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false); // State for edit mode
  const [selectedFruit, setSelectedFruit] = useState(null); // State to hold selected fruit details
  const [fruitName, setFruitName] = useState("");
  const [fruitContent, setFruitContent] = useState("");
  const [fruitImage, setFruitImage] = useState(null);

  const dispatch = useDispatch();
  const { t } = useTranslation();

  useEffect(() => {
    const id = localStorage.id;
    if (id !== "undefined") {
      dispatch(getlanguage(id)).then((res) => {
        setlanguage(res.payload.language);
        dispatch(getfruitdata(res.payload.language)).then((response) => {
          setImageData(response.payload.data);
        });
      });
    }
  }, [dispatch]);

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const filteredFruits = imageData.filter((item) => {
    return item.name.toLowerCase().includes(searchTerm.toLowerCase());
  });


// Function to handle delete
const handleDelete = (id) => {
  Swal.fire({
    title: 'Are you sure?',
    text: "This action cannot be undone.",
    icon: 'warning',
    showCancelButton: true,
    confirmButtonColor: '#3085d6',
    cancelButtonColor: '#d33',
    confirmButtonText: 'Yes, delete it!'
  }).then((result) => {
    if (result.isConfirmed) {
      const data = { id, lang };
      dispatch(Deletefruitdata(data))
        .then((response) => {
          if (response.payload && response.payload.response === 'S') {
            dispatch(getfruitdata(lang)).then((response) => {
              if (response.payload) {
                setImageData(response.payload.data);
                Swal.fire(
                  'Deleted!',
                  'Your fruit has been deleted.',
                  'success'
                );
              } else {
                console.error('Error fetching fruit data:', response);
              }
            }).catch(error => {
              console.error('Error fetching fruit data:', error);
            });
          } else {
            Swal.fire(
              'Error!',
              'There was a problem deleting the fruit.',
              'error'
            );
            console.error('Error deleting fruit:', response);
          }
        })
        .catch(error => {
          Swal.fire(
            'Error!',
            'There was a problem deleting the fruit.',
            'error'
          );
          console.error('Error deleting fruit:', error);
        });
    }
  });
};


  const handleEdit = (id) => {
    const fruit = imageData.find((item) => item._id === id);
    if (fruit) {
      setSelectedFruit(fruit);
      setFruitName(fruit.name);
      setFruitContent(fruit.content);
      setFruitImage(fruit.img || null);
      setIsEditMode(true);
      setModal(true);
    }
  };

  const handleSave = () => {
    if (!fruitName && fruitName ==='' || !fruitContent && fruitContent ==='') {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Please fill in all fields.'
      });
      return;
    }

    const formData = new FormData();
    formData.append("id", selectedFruit._id);
    formData.append("name", fruitName);
    formData.append("content", fruitContent);
    formData.append("lang", lang);
    if (fruitImage) formData.append("img", fruitImage);

    dispatch(postEdidedfruitdata(formData))
      .then((response) => {
        if (response.payload.response === 'S') {
          dispatch(getfruitdata(lang)).then((response) => {
            setImageData(response.payload.data);
          });
          setModal(false);
          setIsEditMode(false);
          Swal.fire({
            icon: 'success',
            title: 'Success!',
            text: 'Fruit details have been added successfully.'
          });
          
        } else {
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'Error saving fruit:'
          });
        }
      })
      .catch(error => {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'Error saving fruit:'
        });
      });
  };

  const handleAddFruit = () => {
    // if (!fruitName || !fruitContent ) {
    //   Swal.fire({
    //     icon: 'error',
    //     title: 'Oops...',
    //     text: 'Please fill in all fields.'
    //   });
    //   return;
    // }
  
    const formData = new FormData();
    formData.append("img", fruitImage);
    formData.append("content", fruitContent);
    formData.append("name", fruitName);
    formData.append("lang", lang);
  
    dispatch(postfruitdata(formData))
      .then((response) => {
        if (response.payload.response === 'S') {
          // Fetch the updated fruit data
          dispatch(getfruitdata(lang)).then((response) => {
            setImageData(response.payload.data);
          });
  
          // Display success message
          Swal.fire({
            icon: 'success',
            title: 'Success!',
            text: 'Fruit details have been added successfully.'
          });
  
          // Close the modal
          setModal(false);
          // Clear all form data
          setFruitName("");
          setFruitContent("");
          setFruitImage(null);
        }
      })
      .catch((error) => {
        // Display error message
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'Error adding fruit.'
        });
        console.error('Error adding fruit:', error);
      });
  };
  

  const toggleModal = () => {
    setModal(!modal);
    if (modal) {
      setIsEditMode(false); // Reset edit mode when closing the modal
      setSelectedFruit(null);
      setFruitName("");
      setFruitContent("");
      setFruitImage(null);
    }
  };

  return (
    <Fragment>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <div style={{ width: '75%' }}>
          <Breadcrumbs title={t('Fruits')} data={[{ title: t('Fruits') }]} />
        </div>
        <div>
          <Button style={{ width: '100%' }} color="primary" onClick={() => {
            setIsEditMode(false); // Ensure we're not in edit mode when adding a new fruit
            setModal(true); // Open the modal for adding new fruit
          }}>
            Add Fruit
          </Button>
        </div>
      </div>

      <div className="shopping-cart mb-3">
        <input
          type="text"
          placeholder="Search Fruit Name..."
          value={searchTerm}
          onChange={handleSearchChange}
          className="form-control text-black"
        />
      </div>
      <div>
        <table className="table table-bordered">
          <thead>
            <tr>
            <th style={{ backgroundColor: 'white', textAlign: 'center' }}>{t('No')}</th>
              <th style={{ backgroundColor: 'white', textAlign: 'center' }}>{t('Name')}</th>
              <th style={{ backgroundColor: 'white', textAlign: 'center' }}>{t('Actions')}</th>
            </tr>
          </thead>
          <tbody>
            {filteredFruits.map((item, index) => (
              <tr key={index}>
                <td style={{ textAlign: 'center' }}>{t(index+1)}</td>
                <td style={{ textAlign: 'center' }}>{t(item.name)}</td>
                <td>
                  <div style={{ justifyContent: 'space-around' }} className="d-flex">
                    <Link to={`/fruits-details/${item._id}`}>
                      <svg style={{ color: 'green' }} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-eye"><path d="M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z"></path><circle cx="12" cy="12" r="3"></circle></svg>
                    </Link>
                    <Button color="primary" className="shadow btn-xs sharp me-1" onClick={() => handleEdit(item._id)}>
                      <i className="fas fa-pen"></i>
                    </Button>
                    <Button color="danger" className="shadow btn-xs sharp" onClick={() => handleDelete(item._id)}>
                      <i className="fa fa-trash"></i>
                    </Button>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {/* Add/Edit Fruit Modal */}
      <Modal isOpen={modal} size="lg" toggle={toggleModal}>
        <ModalHeader toggle={toggleModal}>
          {isEditMode ? "Edit Fruit" : "Add Fruit"}
        </ModalHeader>
        <ModalBody>
          <Form>
            <FormGroup>
              <Label for="fruitName">Fruit Name</Label>
              <Input
                type="text"
                id="fruitName"
                value={fruitName}
                onChange={(e) => setFruitName(e.target.value)}
              />
            </FormGroup>
            <FormGroup>
              <Label for="fruitContent">Fruit Content</Label>
              <Input
              style={{height:'40vh'}}
                type="textarea"
                id="fruitContent"
                value={fruitContent}
                onChange={(e) => setFruitContent(e.target.value)}
              />
            </FormGroup>
            <FormGroup>
              <Label for="fruitImage">Upload Image</Label>
              {fruitImage ? (
                <div style={{ position: 'relative' }}>
                  <img
                    src={typeof fruitImage === 'string' ? fruitImage : URL.createObjectURL(fruitImage)}
                    alt="Fruit"
                    style={{ width: '100px', height: '100px' }}
                  />
                  <Button close onClick={() => setFruitImage(null)} />
                </div>
              ) : (
                <div>
                  <Button color="primary" onClick={() => document.getElementById('fruitImageUpload').click()}>+</Button>
                  <Input
                    type="file"
                    id="fruitImageUpload"
                    style={{ display: 'none' }}
                    onChange={(e) => {
                      const file = e.target.files[0];
                      if (file) {
                        const modifiedFileName = file.name.replace(/\s+/g, '_');
                        const modifiedFile = new File([file], modifiedFileName, { type: file.type });
                        setFruitImage(modifiedFile);
                      }
                    }}
                  />
                </div>
              )}
            </FormGroup>
          </Form>
        </ModalBody>
        <ModalFooter>
          <Button
            color="primary"
            onClick={isEditMode ? handleSave : handleAddFruit}
            disabled={!fruitName || !fruitContent}
          >
            {isEditMode ? "Save" : "Add Fruit"}
          </Button>
        </ModalFooter>

      </Modal>
    </Fragment>
  );
};

export default Fruits;
