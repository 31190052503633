import webservice from '../../../../services/webservice';
import { createAsyncThunk } from '@reduxjs/toolkit'

export const getParentCheckupName = createAsyncThunk('Medical/getParentCheckupName', async (lang) => {
    try {
        const response = await fetch(webservice + 'api/getParentCheckupName', {
            body: JSON.stringify({ lang }),
            method: 'POST',
            headers: {
                'Content-type': 'application/json; charset=UTF-8',
            }
        });
        const responseData = await response.json();
        return responseData;
    } catch (error) {
        console.error('Error:', error);
        throw error;
    }
});


export const getdata = createAsyncThunk('Medical/getdata', async (lang) => {
    try {
        const response = await fetch(webservice + 'api/getMedicalChecklist', {
            body: JSON.stringify({ lang }),
            method: 'POST',
            headers: {
                'Content-type': 'application/json; charset=UTF-8',
            }
        });
        const responseData = await response.json();
        return responseData;
    } catch (error) {
        console.error('Error:', error);
        throw error;
    }
});

export const addExistingMedicalChecklist = createAsyncThunk('Medical/postMedicalChecklist', async (data) => {
    try {
        const response = await fetch(webservice + 'api/addExistingMedicalChecklist', {
            body: JSON.stringify({ data }),
            method: 'POST',
            headers: {
                'Content-type': 'application/json; charset=UTF-8',
            }
        });
        const responseData = await response.json()
        return responseData;
    } catch (error) {
        console.error('Error:', error);
        throw error;
    }
});

export const testdetails = createAsyncThunk('Medical/testdetails', async (id) => {
    try {
        const response = await fetch(webservice + 'api/medicalCheckdetails', {
            method: 'POST',
            body: JSON.stringify({ id }),
            headers: {
                'Content-type': 'application/json; charset=UTF-8',
            }
        });
        const responseData = await response.json();
        return responseData;
    } catch (error) {
        console.error('Error:', error);
        throw error;
    }
});

export const deleteCheckupdetails = createAsyncThunk('Medical/deleteCheckupdetails', async (data) => {
    try {
        const response = await fetch(webservice + 'api/deleteMedicalCheckupList', {
            method: 'POST',
            body: JSON.stringify({ data }),
            headers: {
                'Content-type': 'application/json; charset=UTF-8',
            }
        });
        const responseData = await response.json();
        return responseData;
    } catch (error) {
        console.error('Error:', error);
        throw error;
    }
});