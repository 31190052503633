import webservice from '../../../../services/webservice';
import {createAsyncThunk } from '@reduxjs/toolkit'

export const getParentFirstAid = createAsyncThunk('Vaccine/getParentFirstAid', async (lang) => {
    try {
        const response = await fetch(webservice + 'api/getParentFirstAid', {
            body: JSON.stringify({ lang }),
            method: 'POST',
            headers: {
                'Content-type': 'application/json; charset=UTF-8',
            }
        });
        const responseData = await response.json();
        return responseData;
    } catch (error) {
        console.error('Error:', error);
        throw error;
    }
});

export const getdata = createAsyncThunk('Vaccine/getFirstAidlist', async (lang) => {
    try {
        const response = await fetch(webservice + 'api/getFirstAidlist', {
            body: JSON.stringify({ lang }),
            method: 'POST',
            headers: {
                'Content-type': 'application/json; charset=UTF-8',
            }
        });

        const responseData = await response.json();
        return responseData;
    } catch (error) {
        console.error('Error:', error);
        throw error; 
    }
});

export const addExistingFirstAidlist = createAsyncThunk('Vaccine/addExistingFirstAidlist', async (data) => {
    try {
        const response = await fetch(webservice + 'api/addExistingFirstAidlist', {
            body: JSON.stringify({ data }),
            method: 'POST',
            headers: {
                'Content-type': 'application/json; charset=UTF-8',
            }
        });

        const responseData = await response.json()
        return responseData;
    } catch (error) {
        console.error('Error:', error);
        throw error;
    }
});

export const deleteFirstAiddetails = createAsyncThunk('Vaccine/deleteFirstAiddetails', async (data) => {
    try {
        const response = await fetch(webservice + 'api/deleteFirstAidlist', {
            method: 'POST',
            body: JSON.stringify({ data }),
            headers: {
                'Content-type': 'application/json; charset=UTF-8',
            }
        });

        const responseData = await response.json();


        return responseData;
    } catch (error) {
        console.error('Error:', error);
        throw error; 
    }
});