import React, { Fragment, useState } from "react";
import "../../../css/custom.css";
import { Card, CardBody, Button, Form, FormGroup, Label, Input, Alert } from "reactstrap";
import { useDispatch } from "react-redux";
import { AddStress } from "./store";
import { Link, useNavigate } from "react-router-dom";
import { AesDecrypt } from "../../../services/crypto";
import { useTranslation } from 'react-i18next';
import BreadCrumbs from "../../breadcrumbs";
import Swal from "sweetalert2";

const Stress = () => {
  const dispatch = useDispatch();
  const [stress, setStress] = useState({});
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [selectedChoice, setSelectedChoice] = useState("");
  const [error, setError] = useState("");
  const { t } = useTranslation();
  const navigate = useNavigate();
  const userId = localStorage.id;

  const handleChoiceSelection = (choice) => {
    setSelectedChoice(choice);
    setError("");
  };

  const handleNextQuestion = (e) => {
    e.preventDefault();
    if (!selectedChoice) {
      setError(t("Please select an option"));
      return;
    }
    const currentQuestion = stressQuestions[currentQuestionIndex].id;
    setStress((prevStress) => ({ ...prevStress, [currentQuestion]: selectedChoice }));
    setSelectedChoice(stress[stressQuestions[currentQuestionIndex + 1]?.id] || "");
    setCurrentQuestionIndex((prevIndex) => prevIndex + 1);
  };

  const handlePreviousQuestion = (e) => {
    e.preventDefault();
    setError(""); // Clear any existing error
    setSelectedChoice(stress[stressQuestions[currentQuestionIndex - 1].id] || "");
    setCurrentQuestionIndex((prevIndex) => prevIndex - 1);
  };


  const handleSaveMood = (e) => {
    e.preventDefault();
    if (!selectedChoice) {
      setError(t("Please select an option"));
      return;
    }
    const currentQuestion = stressQuestions[currentQuestionIndex].id;
    const StressData = { ...stress, [currentQuestion]: selectedChoice };
    const data = { userId, stress: StressData };

    dispatch(AddStress(data)).then((response) => {
      const Response = response.payload.response;
      if (Response === "S") {
        Swal.fire({
          title: t("Stress Test Successful"),
          icon: "success",
        });
        navigate('/mentalhealth');
      }
    });
    setStress({});
    setSelectedChoice("");
    setCurrentQuestionIndex(0);
  };

  const stressQuestions = [
    { question: t("1.In the last month, how often have you been upset because of something that happened unexpectedly?"), id: "s1" },
    { question: t("2.In the last month, how often have you felt that you were unable to control the important things in your life?"), id: "s2" },
    { question: t("3.In the last month, how often have you felt nervous and stressed?"), id: "s3" },
    { question: t("4.In the last month, how often have you found that you could not cope with all the things that you had to do?"), id: "s4" },
    { question: t("5.In the last month, how often have you been angered because of things that happened that were outside of your control?"), id: "s5" },
    { question: t("6.In the last month, how often have you felt difficulties were piling up so high that you could not overcome them?"), id: "s6" },
    { question: t("7.Feeling afraid as if something awful might happen"), id: "s7" },
    { question: t("8.In the last month, how often have you felt that you were on top of things?"), id: "s8" },
    { question: t("9.In the last month, how often have you been able to control irritations in your life?"), id: "s9" },
    { question: t("10.In the last month, how often have you felt that things were going your way?"), id: "s10" },
    { question: t("11.In the last month, how often have you felt confident about your ability to handle your personal problems?"), id: "s11" }
  ];

  const stressChoices1 = [
    { label: t("Never"), value: "0" },
    { label: t("Almost Never"), value: "1" },
    { label: t("Sometimes"), value: "2" },
    { label: t("Fairly often"), value: "3" },
    { label: t("Very often"), value: "4" }
  ];

  const stressChoices2 = [
    { label: t("Never"), value: "4" },
    { label: t("Almost Never"), value: "3" },
    { label: t("Sometimes"), value: "2" },
    { label: t("Fairly often"), value: "1" },
    { label: t("Very often"), value: "0" }
  ];

  const getChoicesForQuestion = (questionId) => {
    const idNumber = parseInt(questionId.replace("s", ""), 10);
    return idNumber <= 7 ? stressChoices1 : stressChoices2;
  };

  const isLastQuestion = currentQuestionIndex === stressQuestions.length - 1;
  const isFirstQuestion = currentQuestionIndex === 0;

  return (
    <Fragment>
      <BreadCrumbs title={t('Stress')} data={[{ title: t('Stress') }]} />
      <span className="mentalhealth text-black" style={{ fontSize: "25px" }}>{t('Over the last 2 weeks, how often have you been bothered by the following problems?')}</span>
      <Card style={{ marginTop: "2%", boxShadow: "0px 0px 10px 5px rgb(144 144 144 / 56%)" }}>
        <CardBody>
          <Form onSubmit={isLastQuestion ? handleSaveMood : handleNextQuestion}>
            <FormGroup tag="fieldset">
              <legend className="text-black" style={{ fontSize: "30px" }}>{stressQuestions[currentQuestionIndex].question}</legend>
              {getChoicesForQuestion(stressQuestions[currentQuestionIndex].id).map((choice, i) => (
                <FormGroup className="text-black" check key={i}>
                  <Label check>
                    <Input
                      type="checkbox"
                      className="form-check-input mb-4"
                      value={choice.value}
                      checked={selectedChoice === choice.value}
                      onChange={() => handleChoiceSelection(choice.value)}
                    />
                    {choice.label}
                  </Label>
                </FormGroup>
              ))}
            </FormGroup>
            {error && <Alert color="danger">{error}</Alert>}
            <div style={{ marginLeft: "75%" }}>
              {!isFirstQuestion && (
                <Button color="secondary" onClick={handlePreviousQuestion} style={{ marginRight: "10px" }}>
                  {t('Previous')}
                </Button>
              )}
              <Button color="primary" type="submit" className="small-button" >
                {isLastQuestion ? t('Save') : t('Next')}
              </Button>
            </div>
          </Form>
        </CardBody>
      </Card>
    </Fragment>
  );
};

export default Stress;
