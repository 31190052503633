import webservice from '../../../../services/webservice'
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { AesEncrypt } from '../../../../services/crypto';


// export const getfamilyData = createAsyncThunk('AppMenu/getfamilyData', async (Id) => {
//     const Encparams = AesEncrypt(Id)
//     try {
//         const response = await fetch(webservice + 'api/getFamilyMembers', {
//             method: 'POST',
//             body: JSON.stringify({ Encparams }),
//             headers: {
//                 'Content-type': 'application/json; charset=UTF-8',
//             }
//         })
//         const responseData = await response.json();
//         return responseData;
//     } catch (err) {
//         console.error(err);
//         throw err;
//     }
// })

export const getdataFlag = createAsyncThunk('AppMenu/getfamilyData', async (Id) => {
    const encryptedData = AesEncrypt(Id)
    try {
        const response = await fetch(webservice + 'api/getDataFlag', {
            method: 'POST',
            body: JSON.stringify({ encryptedData: encryptedData }),
            headers: {
                'Content-type': 'application/json; charset=UTF-8',
            }
        })
        const responseData = await response.json();

        return responseData;
    } catch (err) {
        console.error(err);
        throw err;
    }
})

export const switchuseraccount = createAsyncThunk('profile/switchuseraccount', async (switchid) => {
    const Encparams = AesEncrypt(switchid)
    const response = await fetch(webservice + 'api/switchUser', {
        method: 'POST',
        body: JSON.stringify({ Encparams }),
        headers: {
            'Content-type': 'application/json; charset=UTF-8',
        }
    })
    const responseData = await response.json();
    return responseData;
})

export const Updatepassword = createAsyncThunk('AppMenu/getheader', async (data) => {
    const Encparams = AesEncrypt(data)
    try {
        const response = await fetch(webservice + 'api/changePassword', {
            method: 'POST',
            body: JSON.stringify({ Encparams }),
            headers: {
                'Content-type': 'application/json; charset=UTF-8',
            }
        })
        const responseData = await response.json();
        return responseData;
    } catch (err) {
        console.error(err);
        throw err;
    }
})

// export const getprofile = createAsyncThunk('profile/getprofile', async (data) => {
//     const encryptedData = AesEncrypt(data)
//     const response = await fetch(webservice + 'api/getprofile', {
//         method: 'POST',
//         body: JSON.stringify({ data: encryptedData }), // Send updated data directly
//         headers: {
//             'Content-type': 'application/json; charset=UTF-8',
//         }
//     });
//     // Dispatch an action to get all data after update
//     const responseData = await response.json();
//     return responseData; // Return the updated data
// });

// export const userdataflag = createAsyncThunk('sidemenu/userDataFlag', async (data) => {

//     const response = await fetch(webservice + 'api/userDataFlag', {
//         method: 'POST',
//         body: JSON.stringify({ data }), // Send updated data directly
//         headers: {
//             'Content-type': 'application/json; charset=UTF-8',
//         }
//     });
//     // Dispatch an action to get all data after update
//     const responseData = await response.json();
//     return responseData; // Return the updated data
// });


export const getLanguage = createAsyncThunk('sidemenu/userDataFlag', async (data) => {

    const response = await fetch(webservice + 'api/getAdminLanguage', {
        method: 'POST',
        body: JSON.stringify({ data }), // Send updated data directly
        headers: {
            'Content-type': 'application/json; charset=UTF-8',
        }
    });
    // Dispatch an action to get all data after update
    const responseData = await response.json();
    return responseData; // Return the updated data
});

export const updateLanguage = createAsyncThunk('sidemenu/updateAdminLanguage', async (data) => {
    const response = await fetch(webservice + 'api/updateAdminLanguage', {
        method: 'POST',
        body: JSON.stringify({ data }), // Send updated data directly
        headers: {
            'Content-type': 'application/json; charset=UTF-8',
        }
    });
    // Dispatch an action to get all data after update
    const responseData = await response.json();
    return responseData; // Return the updated data
});

export const getPassword = createAsyncThunk('UserType/getPassword', async (data) => {
    const Encparams = AesEncrypt(data)
    try {
        const response = await fetch(webservice + 'api/getPassword', {
            method: 'POST',
            body: JSON.stringify({ Encparams }), // Pass the entire data object directly
            headers: {
                'Content-type': 'application/json; charset=UTF-8',
            }
        });

        const responseData = await response.json();
        return responseData;
    } catch (error) {
        console.error('Error:', error);
        throw error; // Rethrow the error to be handled by Redux Toolkit
    }
});

// export const getUserType = createAsyncThunk('UserType/getUserType', async (data) => {
//     const Encparams = AesEncrypt(data)
//     try {
//         const response = await fetch(webservice + 'api/getuserType', {
//             method: 'POST',
//             body: JSON.stringify({ Encparams }), // Pass the entire data object directly
//             headers: {
//                 'Content-type': 'application/json; charset=UTF-8',
//             }
//         });

//         const responseData = await response.json();
//         return responseData;
//     } catch (error) {
//         console.error('Error:', error);
//         throw error; // Rethrow the error to be handled by Redux Toolkit
//     }
// });

export const changeActivestatus = createAsyncThunk('UserType/changeActivestatus', async (data) => {
    const Encparams = AesEncrypt(data)
    try {
        const response = await fetch(webservice + 'api/profileStatus', {
            method: 'POST',
            body: JSON.stringify({ Encparams }), // Pass the entire data object directly
            headers: {
                'Content-type': 'application/json; charset=UTF-8',
            }
        });

        const responseData = await response.json();
        return responseData;
    } catch (error) {
        console.error('Error:', error);
        throw error; // Rethrow the error to be handled by Redux Toolkit
    }
});

export const deleteUser = createAsyncThunk('UserType/changeActivestatus', async (data) => {
    const Encparams = AesEncrypt(data)
    try {
        const response = await fetch(webservice + 'api/activeStatus', {
            method: 'POST',
            body: JSON.stringify({ Encparams }), // Pass the entire data object directly
            headers: {
                'Content-type': 'application/json; charset=UTF-8',
            }
        });

        const responseData = await response.json();
        return responseData;
    } catch (error) {
        console.error('Error:', error);
        throw error; // Rethrow the error to be handled by Redux Toolkit
    }
});