import React, { Fragment, useState } from "react";
import { useDispatch } from 'react-redux';
import { Row, Col, Card, Table, Button, Modal } from "react-bootstrap";
// import { updateBloodPressureData, deleteBloodPressureData } from "../store";
import DatePicker from "react-datepicker";

import { Badge } from "reactstrap";
import { AesDecrypt, AesEncrypt } from '../../../../services/crypto';

import { useDropzone } from "react-dropzone";
import "../DocumentUpload.css";
import { postDoctorPrescription, getselectedData, updateDoctorPrescription, deleteDoctorPrescription } from "../store/index";
import "react-datepicker/dist/react-datepicker.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilePdf } from '@fortawesome/free-solid-svg-icons';
import Select from "react-select";
import classnames from "classnames";
import { useTranslation } from 'react-i18next'
import moment from "moment";

const Options = [
  { value: "Lab Report", key: "0", label: "LAB REPORT" },
  { value: "Prescription", key: "1", label: "PRESCRIPTION" },
  { value: "Invoice", key: "2", label: "INVOICE" },
];


const BloodPressureTable = ({ alldata, handleReload }) => {

  const [editingIndex, setEditingIndex] = useState(null);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deleteIndex, setDeleteIndex] = useState(null);
  const [editedData, setEditedData] = useState({});
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [basicModal, setBasicModal] = useState(false);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [documentName, setDocumentName] = useState("");
  const [testDate, setTestDate] = useState(null);
  const [selectedRecord, setSelectedRecord] = useState("RecordType");
  const [selectedViewFile, setSelectedViewFile] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [documentNameError, setDocumentNameError] = useState('');
  const [testDateError, setTestDateError] = useState('');
  const [selectedRecordError, setSelectedRecordError] = useState('');
  const [fileError, setFileError] = useState('');


  const handleEdit = (index, data) => {
    setEditingIndex(index);
    setEditedData({ ...data });
  };

  const handleDelete = (index) => {
    setDeleteIndex(index);
    setShowDeleteModal(true);
  };

  const handleConfirmDelete = (id) => {
    setShowDeleteModal(false);
    dispatch(deleteDoctorPrescription(id)).then(() => {
      handleReload()
    })

  };

  const handleCloseDeleteModal = () => {
    setShowDeleteModal(false);
  };


  const handleSave = (index) => {
    // Implement saving edited data logic here
    const { ...editedFields } = editedData;
    dispatch(updateDoctorPrescription({ data: editedFields })).then(() => {
      handleReload()
      setEditingIndex(null);
    })
  };


  const handlediscard = (index) => {
    // Implement saving edited data logic here
    setEditingIndex(null);

  };


  const handleInputChange = (value, key) => {
    setEditedData(prevData => ({
      ...prevData,
      [key]: value
    }));
  };

  const handleDateChange = (date1, key) => {
    let date = new Date(date1);
    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const year = date.getFullYear();
    const formattedDate = day + "-" + month + "-" + year;

    setEditedData(prevData => ({
      ...prevData,
      [key]: formattedDate
    }));
  };


  const handleView = async (data) => {
    const response = await dispatch(getselectedData(data));
    if (response.payload.response === 'S') {
      const decryptedData = AesDecrypt(response.payload.data)
      setSelectedViewFile(decryptedData[0]);
      setShowModal(true);
    }

  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const onDrop = (acceptedFiles) => {

    const filteredFiles = acceptedFiles.filter(
      (file) =>
        file.type === "image/jpeg" ||
        file.type === "image/png" ||
        file.type === "application/pdf"
    );
    setSelectedFiles([...selectedFiles, ...filteredFiles]);
  };

  const removeFile = (fileName) => {
    setSelectedFiles(selectedFiles.filter((file) => file.name !== fileName));
  };

  const userById = localStorage.userDetails;

  const userDetails = JSON.parse(userById);

  const reset = () => {
    setSelectedRecord("RecordType")
    setDocumentName("")
    setTestDate(null)
    setSelectedFiles([])
    setDocumentNameError('');
    setTestDateError('');
    setSelectedRecordError('');
    setFileError('');
  }

  const handleUpload = () => {
    let userId = "";

    // Reset error states
    setDocumentNameError('');
    setTestDateError('');
    setSelectedRecordError('');
    setFileError('');

    let hasError = false;

    // Validate documentName
    if (!documentName) {
      setDocumentNameError(t('Document Name is required'));
      hasError = true;
    }

    // Validate testDate
    if (testDate === null) {
      setTestDateError(t('Test Date is required'));
      hasError = true;
    }

    // Validate selectedRecord
    if (!selectedRecord || selectedRecord === "RecordType") {
      setSelectedRecordError(t('Record Type is required'));
      hasError = true;
    }

    // Validate selectedFiles
    if (selectedFiles.length === 0) {
      setFileError(t('Please select at least one file'));
      hasError = true;
    }

    // If there are validation errors, return early
    if (hasError) {
      return;
    }

    // Determine userId
    if (userDetails && userDetails._id) {
      userId = userDetails._id;
    } else if (userDetails && userDetails.data && userDetails.data._id) {
      userId = userDetails.data._id;
    } else {
      console.error(t("User ID is not available"));
      return;
    }

    // Format date
    const formatDate = (date) => {
      return moment(date).format('DD-MM-YYYY');
    };

    // Prepare formData
    const formData = new FormData();
    selectedFiles.forEach((file) => {
      formData.append(`file`, file);
    });

    const FormDataArr = {
      documentName: documentName,
      documentType: selectedRecord.value,
      testDate: formatDate(testDate),
      userId: userId
    };

    formData.append(
      `fileData`,
      JSON.stringify({ Encparams: AesEncrypt(FormDataArr) })
    );

    // Dispatch the action
    dispatch(postDoctorPrescription(formData))
      .then(() => {
        handleReload();
        setBasicModal(false);
        setSelectedRecord("RecordType");
        setDocumentName("");
        setTestDate(null);
        setSelectedFiles([]);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };


  const { getRootProps, getInputProps } = useDropzone({ onDrop });
  const [fullScreenView, setFullScreenView] = useState(false);
  const [fullScreenData, setFullScreenData] = useState(null);

  const handleOpenFullScreen = (data) => {
    setFullScreenData(data);
    setFullScreenView(true);
  };


  const handleDownload = () => {
    // Assuming fullScreenData contains the file data
    const { image, pdf, filename } = fullScreenData;

    // Create a blob for the file data
    const blob = image ? base64ToBlob(image, 'image/png') :
      pdf ? base64ToBlob(pdf, 'application/pdf') :
        null;

    if (blob) {
      // Create a URL for the blob
      const url = URL.createObjectURL(blob);

      // Create an anchor element for downloading
      const a = document.createElement('a');
      a.href = url;
      a.download = filename;

      // Trigger the click event to download the file
      document.body.appendChild(a);
      a.click();

      // Cleanup
      document.body.removeChild(a);
      URL.revokeObjectURL(url);
    }
  };
  // Function to convert base64 to blob
  const base64ToBlob = (base64Data, contentType) => {
    const byteCharacters = atob(base64Data);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    return new Blob([byteArray], { type: contentType });
  };




  const CustomInput = React.forwardRef(({ value, onClick }, ref) => (
    <input
      ref={ref}
      value={value}
      placeholder="Test Date"
      onClick={onClick}
      readOnly
      className={`form-control ${testDateError ? 'is-invalid' : ''} text-black`}
    />
  ));


  return (
    <Fragment>
      <Row>
        <div>
          <div  style={{display:'flex',justifyContent:'flex-end',marginRight:'2%',width:'auto'}}>
            <Badge
              onClick={() => setBasicModal(true)}
              color="primary"
              className="btn-add-document"
            >       
              {t('Add Document')}
            </Badge>
          </div>
        </div>
        <Card.Body>
          <Table style={{width:'98%',marginLeft:'1%' }} responsive striped bordered hover>
            <thead className="text-wrap-responsive"  >
              <tr>
                <th style={{maxWidth:'100px'}}>{t('No')}</th>
                <th>{t('Record Name')}</th>
                <th>{t('Record Type')}</th>
                <th> {t('Test Date')}</th>
                <th>{t('File Count')}</th>
                <th> {t('Edit')}</th>
                {editingIndex !== null && (
                  <th> {t('Discard')}</th>
                )}
                {editingIndex === null && (
                  <th> {t('Delete')}</th>
                )}
                {editingIndex === null && (
                  <th> {t('View')}</th>
                )}
              </tr>
            </thead>
            <tbody className="text-wrap-responsive">

              {Array.isArray(alldata) && alldata.length > 0 ? (

                alldata.map((data, index) => (

                  <tr key={index}>

                 
                    <td  style={{maxWidth:'100px'}} >{index + 1}</td>
                    <td>
                      {editingIndex === index ? (
                        <input
                          type="text"
                          value={editedData.documentName || ''}
                          onChange={(e) => handleInputChange(e.target.value, 'documentName')}
                        />
                      ) : (
                        data.documentName
                      )}
                    </td>


                    <td>
                      {editingIndex === index ? (

                        <Select
                          isClearable={false}
                          options={Options}
                          classNamePrefix="select"
                          placeholder={t("Record Type")}
                          className={classnames("react-select select-borderless", { 'is-invalid': selectedRecordError })}
                          value={Options.find((opt) => opt.value === editedData.documentType)}
                          onChange={(selectedOption) => {
                            setEditedData(prevData => ({
                              ...prevData,
                              ["documentType"]: selectedOption.value
                            }));
                          }}
                          menuPortalTarget={document.body}
                        />

                      ) : (
                        data.documentType
                      )}
                    </td>

                    <td>
                      {editingIndex === index ? (
                        <div style={{ minWidth: '50%' }}>
                          <DatePicker
                            selected={editedData.testDate ? new Date(editedData.testDate.replace(/(\d{2})-(\d{2})-(\d{4})/, "$2-$1-$3")) : null}
                            onChange={(date) => handleDateChange(date, 'testDate')}
                            dateFormat="dd-MM-yyyy"
                            className="date-picker"
                            maxDate={new Date()}
                            minDate={new Date(new Date().setFullYear(new Date().getFullYear() - 100))}
                            customInput={<CustomInput />}

                          />

                        </div>
                      ) : (
                        data.testDate
                      )}
                    </td>

                    <td>
                      {data.filePath.length}
                    </td>
                    <td>
                      {editingIndex === index ? (
                        <Badge color="success" className="btn-edit" onClick={() => handleSave(index)}>
                          {t('Save')}
                        </Badge>
                      ) : (
                        <Badge color="primary" className="btn-edit" onClick={() => handleEdit(index, data)}>
                          {t('Edit')}
                        </Badge>
                      )}
                    </td>
                    <td>
                      {editingIndex === index ? (
                        <Badge color="danger" className="btn-edit" onClick={() => handlediscard(index)}>
                          {t('Discard')}
                        </Badge>
                      ) : (
                        <Badge color="danger" className="btn-edit" onClick={() => handleDelete(index)}>
                          {t('Delete')}
                        </Badge>
                      )}
                    </td>
                    <td>
                      {editingIndex === null && (
                        <Badge color="secondary" className="btn-edit" onClick={() => handleView(data)}>
                          {t('View')}
                        </Badge>
                      )}
                    </td>
                  </tr>
                ))
              ) : (
                // <p style={{ position: 'relative', left: '240%', top: '20px' }}>
                <p style={{ position: 'relative', left: '200%', top: '20px' ,textWrap:'nowrap'}}>
                  No data available to display
                </p>
              )}
            </tbody>
          </Table>
          <Modal
            style={{
              position: 'fixed',
              top: '25%',
              left: '5%'
            }}
            show={showDeleteModal}
            onHide={handleCloseDeleteModal}
          >
            <Modal.Header  >
              <Modal.Title> {t('Delete Confirmation')}</Modal.Title>
            </Modal.Header>
            <Modal.Body>{t('Are you sure you want to delete this record?')}</Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleCloseDeleteModal}>
                {t('Cancel')}
              </Button>
              <Button variant="danger" onClick={() => handleConfirmDelete(alldata[deleteIndex]._id)}>Delete</Button>
            </Modal.Footer>
          </Modal>
        </Card.Body>
        {/* <Card.Body> */}




        
        <div className="bootstrap-modal">
    <Modal className="fade" show={basicModal} onHide={() => setBasicModal(false)} backdrop="static">
        <Modal.Header>
            <Card.Title>{t('ADD YOUR MEDICAL RECORDS HERE')}</Card.Title>
        </Modal.Header>
        <Modal.Body>
            <div className="dropzone-container">
                <div className="form-group">
                    <input
                        type="text"
                        placeholder="Record Name"
                        value={documentName}
                        onChange={(e) => {
                            const inputValue = e.target.value;
                            const capitalizedValue = inputValue.charAt(0).toUpperCase() + inputValue.slice(1);
                            setDocumentName(capitalizedValue);
                        }}
                        className={`form-control ${documentNameError ? 'is-invalid' : ''} text-black`}
                    />
                    {documentNameError && <div style={{ color: 'red', marginTop: '5px' }}>{documentNameError}</div>}

                    <div className="select-container mt-3">
                        <Select
                            isClearable={true}
                            options={Options}
                            classNamePrefix="select"
                            placeholder="Record Type"
                            className={classnames("react-select select-borderless", { 'is-invalid': selectedRecordError })}
                            value={selectedRecord}
                            onChange={(selectedOption) => setSelectedRecord(selectedOption)}
                        />
                    </div>
                    {selectedRecordError && <div style={{ color: 'red', marginTop: '5px' }}>{selectedRecordError}</div>}

                    <div className="mt-3">
                        <DatePicker
                            selected={testDate}
                            value={testDate === null ? testDate : new Date()}
                            onChange={(date) => setTestDate(date)}
                            placeholderText={`${selectedRecord ? selectedRecord.label || selectedRecord : 'Record Type'} Test Date`}
                            className={`form-control ${testDateError ? 'is-invalid' : ''} text-black`}
                            maxDate={new Date()}
                            minDate={new Date(new Date().setFullYear(new Date().getFullYear() - 100))}
                            customInput={<CustomInput />}
                        />
                    </div>
                    {testDateError && <div style={{ color: 'red', marginTop: '5px' }}>{testDateError}</div>}

                    <div {...getRootProps()} className="dropzone mt-3">
                        <input {...getInputProps()} />
                        <p>{t('Drag & drop a file here, or click to select a file')}</p>
                    </div>

                    <div className="added-files-container mt-3">
                        {selectedFiles.map((file, index) => (
                            <div key={index} className="added-file">
                                <span>{file.name}</span>
                                <button
                                    style={{ float: "left", width: "10%" }}
                                    onClick={() => removeFile(file.name)}
                                >
                                    X
                                </button>
                            </div>
                        ))}
                    </div>
                    {fileError && <div style={{ color: 'red', marginTop: '5px' }}>{fileError}</div>}
                </div>
            </div>
        </Modal.Body>

        <Modal.Footer>
            <Row>
                <Col>
                    <Button onClick={() => { setBasicModal(false); reset(); }} variant="danger" className="text-center mt-2 pt-50">
                        {t('Cancel')}
                    </Button>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Button variant="success" onClick={handleUpload} className="text-center mt-2 pt-50">
                        {t('Upload')}
                    </Button>
                </Col>
            </Row>
        </Modal.Footer>
    </Modal>
</div>


        {/* </Card.Body> */}
        <Modal show={showModal} onHide={handleCloseModal} size="lg">
          <Modal.Header style={{ backgroundColor: 'green', color: 'white' }} closeButton>
            <Modal.Title style={{ color: "white" }}> {t('Record Name')}  - {selectedViewFile && selectedViewFile.documentName}</Modal.Title>
          </Modal.Header>
          <Modal.Body style={{ height: '400px' }}>
            <div className="d-flex flex-wrap ">
              {selectedViewFile &&
                selectedViewFile.fileBase64.map((fileData, index) => (
                  <div key={index} className="m-2" style={{ position: 'relative' }}>
                    <Card
                      style={{ width: '150px', cursor: 'pointer', boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)', borderRadius: '20px', overflow: 'hidden' }} // Added borderRadius and overflow: hidden
                      onClick={() => handleOpenFullScreen(fileData)}
                    >
                      {fileData.pdf ? (
                        <FontAwesomeIcon
                          icon={faFilePdf}
                          style={{
                            fontSize: '200px', cursor: "pointer", width: "100%",
                            height: "80%"
                          }}
                        />
                      ) : (
                        <img
                          src={`data:image/png;base64,${fileData.image}`}
                          alt={`Image ${index + 1}`}
                          style={{ width: '100%', height: '150px', objectFit: 'cover' }}
                        />
                      )}
                    </Card>
                    <span style={{ position: 'absolute', top: '90%', left: '10px', color: '#000', padding: '5px', borderRadius: '4px' }}>{fileData.filename}</span>
                  </div>
                ))
              }
            </div>
          </Modal.Body>
        </Modal>

        <Modal show={fullScreenView} onHide={() => setFullScreenView(false)} size="lg"  >
          <Modal.Header closeButton>
            {/* Add the download icon here */}
            <div className="d-flex justify-content-end align-items-center">
              <a href="#" onClick={() => handleDownload()} className="btn btn-link">
                <i className="fas fa-download"></i> {t('Download')}
              </a>
            </div>
          </Modal.Header>
          <Modal.Body style={{ height: '600px' }}>
            {fullScreenData && fullScreenData.image ? (
              <img src={`data:image/png;base64,${fullScreenData.image}`} alt="Full Screen Preview" width="100%" height="100%" />
            ) : fullScreenData && fullScreenData.pdf ? (
              <embed src={`data:application/pdf;base64,${fullScreenData.pdf}`} type="application/pdf" width="100%" height="100%" />
            ) : null}
          </Modal.Body>
        </Modal>

      </Row>
    </Fragment>
  );
};

export default BloodPressureTable;
