import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import loadable from "@loadable/component";
import pMinDelay from "p-min-delay";
import RecomendedSlider from "../Gymove/Home/RecomendedSlider";
import '../../../config/i18n'

import { useTranslation } from 'react-i18next'
import { getbloodData, getbloodsugarlevel, getBmi, getmooddata, getstepcount } from "./store";
import { useDispatch } from "react-redux";
import { Button, Modal, ModalFooter, ModalHeader, ModalBody } from "reactstrap";
import bp from '../../../images/small/blood-pressure.svg'
import sugar from '../../../images/small/blood-sugar.svg'
import bmimage from '../../../images/small/bmi.svg'
import footprint from '../../../images/small/footprint.svg'
import Cycling from '../../../images/small/Cycling.svg'
import '../../../css/custom.css'
import { Link as RouterLink } from 'react-router-dom';
import webservice from "../../../services/webservice";


import Sugarcharts from "./diabeteschart";
import Bloodpressurechart from "./bloodpressurechart";
import { AesDecrypt } from "../../../services/crypto";
import { t } from "i18next";

import { Card } from "react-bootstrap";
import Swal from "sweetalert2";
import axios from "axios";

const defaultMood = (t("How is your Mood today"));

const moodColors = {
  Anger: "red",
  Fear: "orange",
  Sadness: "gray",
  Happy: "#1eac00",
  Cheerful: "blue",
  Romantic: "#ff8096",
  Madness: "#b0b058",
  Calm: "#1eac00",
  NegativeMood: "red",
  Anxious: "#b0b058",
  Irritability: "red",
  Disgust: "orange"
};

const moodOptions = [
  { label: "Happy", emoji: "😁" },
  { label: "Sadness", emoji: "😔" },
  { label: "Fear", emoji: "😨" },
  { label: "Anger", emoji: "😡" },
  { label: "Cheerful", emoji: "💐" },
  { label: "Romantic", emoji: "🥰" },
  { label: "Madness", emoji: "🤯" },
  { label: "Calm", emoji: "😌" },
  { label: "Negative Mood", emoji: "🤬" },
  { label: "Anxious", emoji: "🥴" },
  { label: "Irritability", emoji: "😬" },
  { label: "Disgust", emoji: "🤢" },
  { label: "Nothing", emoji: "😐" },
  { label: t("How is your Mood today"), emoji: "🙂" }
];


const Home = () => {

  const dispatch = useDispatch();

  const [selectChange, setSelectChange] = useState('Monthly');
  const [sugarlevel, setsugarlevel] = useState([]);
  const [systolic, setsystolic] = useState([]);
  const [diastolic, setdiastolic] = useState([]);
  const [bmi, setbmi] = useState([]);
  const [mood, setMood] = useState(defaultMood);
  const [reason, setReason] = useState("");
  const [moodData, setMoodData] = useState({});
  const [loading, setLoading] = useState(true);
  const [steps, setstepcount] = useState(true);

  const { t  } = useTranslation()


  const [expandedReason, setExpandedReason] = useState({});
  const [modalOpen, setModalOpen] = useState(false);
  const [modalReason, setModalReason] = useState("");


  const toggleReason = (index) => {
    setExpandedReason({
      ...expandedReason,
      [index]: !expandedReason[index]
    });
  };

  const toggleModal = () => {
    setModalOpen(!modalOpen);
  };

  const handleReadMore = (reason) => {
    setModalReason(reason);
    toggleModal();
  };





  // useEffect(() => {
  //   // Fetch initial state from backend when component mounts
  //   fetchInitialState();
  // }, []);

  // const fetchInitialState = async () => {
  //   try {
  //     const userId = localStorage.id; // Assuming localStorage.id holds the userId
  //     const response = await axios.get(`${webservice}api/getBloodDonorWillingStatus/${userId}`);
  //     const initialWillingState = response.data.willing === 'ON';
  //     setIsToggled(initialWillingState);
  //   } catch (error) {
  //     console.error('Error fetching initial state:', error);
  //   }
  // };


  // const handleToggle = async () => {
  //   setIsToggled(!isToggled);

  //   // Show SweetAlert based on toggle state
  //   if (!isToggled) {
  //     Swal.fire({
  //       icon: 'success',
  //       title: 'Great!',
  //       text: 'You are now a donor. You can save a life!',
  //     });
  //   } else {
  //     Swal.fire({
  //       icon: 'info',
  //       title: 'Thank you!',
  //       text: 'We will contact you soon to welcome you back as a donor.',
  //     });
  //   }

  //   const message = isToggled ? 'OFF' : 'ON';
  //   try {
  //     const response = await axios.post(`${webservice}api/updateBloodDonorWillingStatus`, {
  //       userId: localStorage.id, // Assuming localStorage.id holds the userId
  //       message: message,
  //     });
  //   } catch (error) {
  //     console.error('Error sending data to backend:', error);
  //   }
  // };






  const renderReason = (reason) => {
    if (reason.length > 10) {
      return (
        <div className="mood-ReadMore" onClick={() => handleReadMore(reason)}>
          {`${reason.substring(0, 7)}... `}Read More
          {/* <p style={{color:"black"}}>Read More</p> */}
        </div>
      );
    } else {
      return <span>{reason}</span>;
    }
  };

  // useEffect(() => {
  //   const _id = localStorage.id;
  //   dispatch(getbloodData(_id))
  //     .then((res) => {
  //       const tempdata = res.payload.data
  //       if (tempdata.length !== 0) {
  //         const data1 = AesDecrypt(tempdata);
  //         setsystolic(data1.systolic)
  //         setdiastolic(data1.diastolic)
  //       }
  //     })
  //     .catch((error) => {
  //       console.error('Error fetching profile:', error);
  //       // Handle error
  //     });
  // }, [dispatch]);


  // useEffect(() => {
  //   const _id = localStorage.id;
  //   dispatch(getbloodsugarlevel(_id))
  //     .then((res) => {
  //       const tempdata = res.payload.data
  //       if (tempdata.length !== 0) {
  //         const data1 = AesDecrypt(tempdata);

  //         setsugarlevel(data1.sugarLevel)
  //       }

  //     })
  //     .catch((error) => {
  //       console.error('Error fetching profile:', error);
  //       // Handle error
  //     });
  // }, [dispatch]);


  // useEffect(() => {
  //   const _id = localStorage.id;
  //   dispatch(getmooddata(_id))
  //     .then((res) => {
  //       const tempdata = res.payload.data
  //       if (tempdata && tempdata.length !== 0) {
  //         const data1 = AesDecrypt(tempdata);
  //         setMoodData(data1.mood);
  //         setReason(data1.reason);
  //         setMood(data1.mood); // Set mood based on fetched data
  //       } else {
  //         // If data is empty or undefined, set default mood and emoji
  //         setMood(defaultMood);
  //         setReason(""); // Optionally set default reason to empty string
  //       }
  //     })
  //     .catch((error) => {
  //       console.error('Error fetching profile:', error);
  //       // Handle error
  //     })
  //     .finally(() => {
  //       setLoading(false); // Update loading state after fetching data
  //     });
  // }, [dispatch]);



  // useEffect(() => {
  //   const _id = localStorage.id;
  //   dispatch(getBmi(_id))
  //     .then((res) => {
  //       const tempdata = res.payload.data
  //       if (tempdata.length !== 0) {
  //         const data1 = AesDecrypt(tempdata);
  //         setbmi(data1.bmi)
  //       }

  //     })
  //     .catch((error) => {
  //       console.error('Error fetching profile:', error);
  //       // Handle error
  //     });
  // }, [dispatch]);

  useEffect(() => {
    if (!loading) {
      const _id = localStorage.id;
      dispatch(getstepcount(_id))
        .then((res) => {
          const tempdata = res.payload.data
          if (tempdata && tempdata.length !== 0) {
            const data1 = AesDecrypt(tempdata);
            setstepcount(data1)
          }
        })
        .catch((error) => {
          console.error('Error fetching profile:', error);
          // Handle error
        });
    }
  }, [dispatch, loading]);

  // if (loading) {
  //   return <div>{t("Loading...")}</div>;  
  // }


  return (
    <div className="row">
      <div className="col-xl-6 col-xxl-12">
        <div className="row">
          <div className="col-sm-6">
            <Link to="/BloodPressure" className="card avtivity-card">
              <div className="card-body">
                <div className="media align-items-center">
                  <span className="activity-icon bgl-success me-md-4 me-3">

                    <img src={bp} />

                  </span>
                  <div className="media-body">
                    <p className="fs-14 mb-2 text-black">{t('Current Blood Pressure')}</p>
                    <span className="titlestta text-black font-w100">{t('Systolic')}: {systolic} {t('Diastolic')}: {diastolic}</span>
                  </div>
                </div>
                <div className="progress" style={{ height: 5 }}>
                  <div
                    className="progress-bar bg-success"
                    style={{ width: "42%", height: 5 }}
                    role="progressbar"
                  >
                  </div>
                </div>
              </div>
              <div className="effect bg-success" />
            </Link>
          </div>
          <div className="col-sm-6">
            <Link to="/Diabetes" className="card avtivity-card">
              <div className="card-body">
                <div className="media align-items-center">
                  <span className="activity-icon bgl-secondary  me-md-4 me-3">
                    <img src={sugar} />
                  </span>
                  <div className="media-body">
                    <p className="fs-14 mb-2 text-black">{t('Current Blood Sugar level')}</p>
                    <span className="title text-black font-w600">{sugarlevel}(mg/dL)</span>
                  </div>
                </div>
                <div className="progress" style={{ height: 5 }}>
                  <div
                    className="progress-bar bg-secondary"
                    style={{ width: "82%", height: 5 }}
                    role="progressbar"
                  >
                    <span className="sr-only">42% {t('Complete')}</span>
                  </div>
                </div>
              </div>
              <div className="effect bg-secondary" />
            </Link>
          </div>


           <div className="col-sm-6">
            <div className="card avtivity-card">
              <div className="card-body">
                <div className="media align-items-center">
                  <span className="activity-icon bgl-danger me-md-4 me-3">
                    <img src={Cycling} />
                  </span>
                  <div className="media-body">
                    <p className="fs-14 mb-2 text-black">{t('Daily Cycling')}</p>
                    <span className="title text-black font-w600">230 Km</span>
                  </div>
                </div>
                <div className="progress" style={{ height: 5 }}>
                  <div
                    className="progress-bar bg-danger"
                    style={{ width: "90%", height: 5 }}
                    role="progressbar"
                  >
                    <span className="sr-only">42% {t('Complete')}</span>
                  </div>
                </div>
              </div>
              <div className="effect bg-danger" />
            </div>
          </div>
          <div className="col-sm-6">
            <Link to="/workout-statistic" className="card avtivity-card">

              <div className="card-body">
                <div className="media align-items-center">
                  <span className="activity-icon bgl-warning  me-md-4 me-3">
                    <img src={footprint} />
                  </span>
                  <div className="media-body">
                    <p className="fs-14 mb-2 text-black">{t('Walk Counts')}</p>
                    <span className="title text-black font-w600">
                      {steps} {t('Steps')}
                    </span>
                  </div>
                </div>
                <div className="progress" style={{ height: 5 }}>
                  <div
                    className="progress-bar bg-warning"
                    style={{ width: "42%", height: 5 }}
                    role="progressbar"
                  >
                  </div>
                </div>
              </div>
              <div className="effect bg-warning" />

            </Link>
          </div>  

          
          <div className="col-sm-6">
            <Link to="/mood_monitor" className="card avtivity-card">
              <div className="card-body">
                <div className="media align-items-center">
                  <span>
                    {moodOptions.map((moodOption) =>
                      moodOption.label === mood ? (
                        <span style={{fontSize:'400%'}} className="me-md-4 me-3" key={moodOption.label} >
                          {moodOption.emoji}
                        </span>
                      ) : null
                    )}
                  </span>
                  <div className="media-body">
                    <p className="fs-14 mb-2 text-black">{t('Your Recent Mood')}</p>
                    <span className="title text-black font-w600">
                      {mood}
                    </span>
                    <h4 style={{ marginTop: "2%", color: moodColors[mood] ? moodColors[mood] : "" }}>
                      {t(reason) }
                    </h4>
                  </div>
                  <Modal isOpen={modalOpen} toggle={toggleModal}>
                    <ModalHeader toggle={toggleModal}><strong>{t('Reason')}</strong></ModalHeader>
                    <ModalBody>
                      <p>{modalReason}</p>
                    </ModalBody>
                    <ModalFooter>
                      <Button color="secondary" onClick={toggleModal}>{t('Close')}</Button>
                    </ModalFooter>
                  </Modal>
                </div>
                <div className="progress" style={{ height: 5 }}>
                  <div
                    className="progress-bar bg-primary"
                    style={{ width: "42%", height: 5 }}
                    role="progressbar"
                  >
                  </div>
                </div>
              </div>
              <div className="effect bg-primary" />
            </Link>
          </div>
          <div className="col-sm-6">
            <Link to="/BMICalculator" className="card avtivity-card">
              <div className="card-body">
                <div className="media align-items-center">
                  <span className="activity-icon bgl-info  me-md-4 me-3">
                    <img src={bmimage} />
                  </span>
                  <div className="media-body">
                    <p className="fs-14 mb-2 text-black">{t('BMI')}</p>
                    <span className="title text-black font-w600">
                      {bmi}(kg/m2)
                    </span>
                  </div>
                </div>
                <div className="progress" style={{ height: 5 }}>
                  <div
                    className="progress-bar bg-info"
                    style={{ width: "42%", height: 5 }}
                    role="progressbar"
                  >
                  </div>
                </div>
              </div>
              <div className="effect bg-info" />
            </Link>
          </div>

        </div>
        <div className="col-xl-9 col-xxl-8" style={{ width: "205%" }}>
          {/* <div className="row"> */}
          <div className="col-xl-6 dashboard-fruit-card" >
            <div className="card" style={{ boxShadow: "0px 0px 5px 5px rgb(144 144 144 / 56%)" }}>
              <div className="card-header d-sm-flex d-block pb-0 border-0">
                <div className="me-auto pr-3">
                  <h4 className="text-black font-w600 fs-20">
                    {t('Nature food Details and Benefits')}
                  </h4>

                </div>

              </div>
              <div className="card-body pt-2">
                <div className="testimonial-one owl-carousel">
                  <RecomendedSlider />
                </div>
              </div>
            </div>
          </div>

          {/* </div> */}
        </div>
      </div>
      <div className="col-xl-6 col-xxl-12">


        {/* <div className={`blood-card ${isToggled ? 'on' : 'off'}`}>
          <div className="text-black" style={{ textAlign: 'center' }}>
            {t('Donate blood')}
          </div>
          &nbsp;
          &nbsp;
          &nbsp;
          &nbsp;
          <div className="toggle-switch" onClick={handleToggle}>
            <div className={`switch ${isToggled ? 'on' : 'off'}`}>
              <span className="toggle-text">{isToggled ? 'On' : 'Off'}</span>
            </div>
          </div>
        </div> */}


        <div className="dashboard-bpsugar-container">
          <Card className="Dashboad-bloodpressure-card">
            <h4 className="text-black">{t('Blood Pressure')}</h4>
            <Bloodpressurechart />
          </Card>
          &nbsp;
          &nbsp;
          <Card className="Dashboad-diabetes-card">
            <h4 className="text-black">{t('Blood Sugar Level')}</h4>
            <Sugarcharts />
          </Card>
        </div>



      </div>




    </div>
  );
};

export default Home;
