// export const AdminMenuList = [
//     //Dashboard
//     {
//         classsChange: 'mm-collapse',
//         iconStyle: <i className="flaticon-381-networking" />,
//         title: 'Dashboard',
//         to: 'dashboard',
//     },

//     {
//         classsChange: 'mm-collapse',
//         iconStyle: <i className="flaticon-381-networking" />,
//         title: 'ADD Data',
//         to: 'addDetails',
//     },
// ]




export const AdminMenuList = [
    //Dashboard

    {
        classsChange: 'mm-collapse',
        iconStyle: <i className="flaticon-381-networking" />,
        title: 'Dashboard',
        to: 'dashboard',
    },

    // {
    //     classsChange: 'mm-collapse',
    //     iconStyle: <i className="fa fa-calendar" />,
    //     title: 'Calendar',
    //     to: 'app-calender',
    // },
    // {
    //     classsChange: 'mm-collapse',
    //     iconStyle: <i className="fa-solid fa-upload"></i>,
    //     title: 'Medical Records',
    //     to: 'Doctor-Prescription'
    // },
    // {
    //     classsChange: 'mm-collapse',
    //     iconStyle: <i className="fas fa-thermometer"></i>,  
    //     title: 'Blood Pressure',
    //     to: 'BloodPressure',
    // },
    // {
    //     classsChange: 'mm-collapse',
    //     iconStyle: <i className="fa-solid fa-heartbeat"></i>,  
    //     title: 'Diabetes',
    //     to: 'Diabetes',
    // },
    // {
    //     classsChange: 'mm-collapse',
    //     // iconStyle: <i className="fa-solid fa-heartbeat"></i>, // Icon representing diet
    //     iconStyle: <i className="fas fa-venus"></i>,
    //     title: 'PeriodTracker',
    //     to: 'PeriodTracker',
    // },

    // {
    //     classsChange: 'mm-collapse',
    //     iconStyle: <i className="fa-solid fa-face-smile"></i>,
    //     title: 'Mood Monitor',
    //     to: 'mood_monitor',
    // },
    // {
    //     classsChange: 'mm-collapse',
    //     iconStyle: <i className="fa-solid fa-hand-holding-droplet"></i>,
    //     title: 'Blood Donation',
    //     to: 'blood',
    // },
    {
        title: 'Aditional Knowledge',
        to: 'flat-icons',
        // update : "NEW", 
        iconStyle: <i className="flaticon-381-compact-disc-1" />,
        content: [
            {
                classsChange: 'mm-collapse',
                iconStyle: <i className="fa-solid fa-microscope"></i>,
                title: 'Medical Checkup List',
                to: 'med_list',
            },

            {
                classsChange: 'mm-collapse',
                iconStyle: <i className="fa-solid fa-kit-medical"></i>,
                title: 'First Aid',
                to: 'first-aid',
            },
            {
                classsChange: 'mm-collapse',
                iconStyle: <i className="fa-solid fa-syringe"></i>,
                title: 'Vaccination',
                to: 'vaccination',
            },
        ]
    }

]