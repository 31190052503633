import webservice from '../../../../services/webservice'
import { createAsyncThunk } from '@reduxjs/toolkit'
import { AesEncrypt } from '../../../../services/crypto';

/** get getProfileData Data By ID */
// export const getProfileData = createAsyncThunk('AppMenu/getProfileData', async (Id) => {
//     const Encparams = AesEncrypt(Id)
//     try {
//         const response = await fetch(webservice + 'api/getUserProfile', {
//             method: 'POST',
//             body: JSON.stringify({ Encparams }),
//             headers: {
//                 'Content-type': 'application/json; charset=UTF-8',
//             }
//         })
//         const responseData = await response.json();
//         return responseData;
//     } catch (err) {
//         console.error(err);
//         throw err;
//     }
// })


// export const getlanguage = createAsyncThunk('sidemenu/getAdminLanguage', async (data) => {

//     const response = await fetch(webservice + 'api/getAdminLanguage', {
//         method: 'POST',
//         body: JSON.stringify({ data }), // Send updated data directly
//         headers: {
//             'Content-type': 'application/json; charset=UTF-8',
//         }
//     });
//     const responseData = await response.json();
//     return responseData; // Return the updated data
// });
// post family members
// export const getprofession = createAsyncThunk('AppMenu/getprofession', async (lang) => {
//     try {
//         const response = await fetch(webservice + 'api/professions', {
//             method: 'POST', // Change method to GET
//             body: JSON.stringify({lang}),
//             headers: {
//                 'Content-type': 'application/json; charset=UTF-8',
//             }
//         });
//         const responseData = await response.json();
//         return responseData;
//     } catch (error) {
//         console.error('Error:', error);
//         throw error; // Rethrow the error to be handled by Redux Toolkit
//     }
// });

// export const getprofession = createAsyncThunk('AppMenu/getprofession', async () => {
//     try {
//         const response = await fetch(webservice + 'api/professions', {
//             method: 'POST', // Change method to GET
//             headers: {
//                 'Content-type': 'application/json; charset=UTF-8',
//             }
//         });
//         const responseData = await response.json();
//         return responseData;
//     } catch (error) {
//         console.error('Error:', error);
//         throw error; // Rethrow the error to be handled by Redux Toolkit
//     }
// });

//Get all Family Data 
// export const getfamilyData = createAsyncThunk('AppMenu/getfamilyData', async (Id) => {
//     const Encparams = AesEncrypt(Id)
//     try {
//         const response = await fetch(webservice + 'api/getFamilyMembers', {
//             method: 'POST',
//             body: JSON.stringify({ Encparams }),
//             headers: {
//                 'Content-type': 'application/json; charset=UTF-8',
//             }
//         })
//         const responseData = await response.json();
//         return responseData;
//     } catch (err) {
//         console.error(err);
//         throw err;
//     }
// })

// Add child User Data
export const Addfamilydata = createAsyncThunk('profile/addfamily', async (data) => {
    const Encparams = AesEncrypt(data)
    const response = await fetch(webservice + 'api/addFamily', {
        method: 'POST',
        body: JSON.stringify({ Encparams }),
        headers: {
            'Content-type': 'application/json; charset=UTF-8',
        }
    })
    const familyData = await response.json();
    return familyData;
})


//Delete Child Users
export const deletemember = createAsyncThunk('AppMenu/deletemember', async (data) => {
    const requestData = {
        childId: data, // Pass the userId in the request body
    };
    const Encparams = AesEncrypt(requestData)
    const response = await fetch(webservice + 'api/deleteFamily', {
        method: 'POST',
        body: JSON.stringify({ Encparams }),
        headers: {
            'Content-type': 'application/json; charset=UTF-8',
        }
    })
    const responseData = await response.json();
    return responseData;
})

//Profile Update User
export const UpdateAction = createAsyncThunk('AppMenu/updateuser', async (data) => {
    const Encparams = AesEncrypt(data)
    const response = await fetch(webservice + 'api/updateMobile', {
        method: 'POST',
        body: JSON.stringify({ Encparams }),
        headers: {
            'Content-type': 'application/json; charset=UTF-8',
        }
    })
    const responseData = await response.json();
    return responseData;
})



/** Edit UserType Data for UserType Page */
export const updateUserData = createAsyncThunk('profile/updateprofile', async (updatedData) => {
    const Encparams = AesEncrypt(updatedData)
    const response = await fetch(webservice + 'api/profileUpdate', {
        method: 'POST',
        body: JSON.stringify({ Encparams }), // Send updated data directly
        headers: {
            'Content-type': 'application/json; charset=UTF-8',
        }
    });
    const responseData = await response.json();
    return responseData; // Return the updated data
});


export const updatemobile = createAsyncThunk('profile/updateprofile', async (data) => {
    const Encparams = AesEncrypt(data)
    const response = await fetch(webservice + 'api/updateUser', {
        method: 'POST',
        body: JSON.stringify({ Encparams }), // Send updated data directly
        headers: {
            'Content-type': 'application/json; charset=UTF-8',
        }
    });
    // Dispatch an action to get all data after update
    const responseData = await response.json();
    return responseData; // Return the updated data
});


// export const getprofile = createAsyncThunk('profile/getprofile', async (data) => {
//     const encryptedData = AesEncrypt(data)
//     const response = await fetch(webservice + 'api/getProfile', {
//         method: 'POST',
//         body: JSON.stringify({ data: encryptedData }), // Send updated data directly
//         headers: {
//             'Content-type': 'application/json; charset=UTF-8',
//         }
//     });
//     // Dispatch an action to get all data after update
//     const responseData = await response.json();
//     return responseData; // Return the updated data
// });


export const removeprofile = createAsyncThunk('profile/removeprofile', async (data) => {
    const encryptedData = AesEncrypt(data)
    const response = await fetch(webservice + 'api/removeProfile', {
        method: 'POST',
        body: JSON.stringify({ data: encryptedData }), // Send updated data directly
        headers: {
            'Content-type': 'application/json; charset=UTF-8',
        }
    });
    // Dispatch an action to get all data after update
    const responseData = await response.json();
    return responseData; // Return the updated data
});

//Switch Users from Main Users
export const switchuseraccount = createAsyncThunk('profile/switchuseraccount', async (switchid) => {
    const Encparams = AesEncrypt(switchid)
    const response = await fetch(webservice + 'api/switchUser', {
        method: 'POST',
        body: JSON.stringify({ Encparams }),
        headers: {
            'Content-type': 'application/json; charset=UTF-8',
        }
    })
    const responseData = await response.json();
    return responseData;
})
