import React, { useEffect, useState } from "react";
import { Modal, Nav, Tab, Card, Col } from "react-bootstrap";
import { Link, useParams } from "react-router-dom";
import { fruitsdetails, getlanguage } from "./store/index";
import { useDispatch } from "react-redux";
import { useTranslation } from 'react-i18next';

const Fruitdetails = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const [AllData, setImageData] = useState(null); // Initialize as null for better conditional checks
  const [loading, setLoading] = useState(true); // State to manage loading
  const { t } = useTranslation();

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const _id = localStorage.id;
        if (_id && _id !== "undefined") {
          const langResponse = await dispatch(getlanguage(_id));
          const lang = langResponse.payload.language;
          const data = { id, lang };
          const fruitsResponse = await dispatch(fruitsdetails(data));
          setImageData(fruitsResponse.payload.data);
        }
      } catch (error) {
        console.error('Error fetching fruit details:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [dispatch, id]);

  // Conditional rendering based on AllData and loading state
  if (loading) {
    return <div>Loading...</div>; // Or any loading indicator you prefer
  }

  if (!AllData || Object.keys(AllData).length === 0) {
    return (
      <>
      <div className='icon_backButton'>
                  <Link to={"/fruits"}>
                    <i className="fas fa-arrow-alt-circle-left"></i>
                  </Link>
                </div>
      <div>
        <h3>No data available to display. Please try again later.</h3>
      </div>
      </>
      
    );
  }

  return (
    <>
      {/* <Link to="/fruits">
       <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-arrow-left"><line x1="19" y1="12" x2="5" y2="12"></line><polyline points="12 19 5 12 12 5"></polyline></svg>
       </Link> */}


      <div className="row">
        <div className="col-lg-12">
          <Card className="card" style={{ boxShadow: "0px 0px 10px 10px rgb(144 144 144 / 56%)" }}>
            <Card.Header>
              <Col style={{ maxWidth: '37px' }}>
                <div className='icon_backButton'>
                  <Link to={"/fruits"}>
                    <i className="fas fa-arrow-alt-circle-left"></i>
                  </Link>
                </div>
              </Col>
              <Col >
                <h2  style={{textAlign:'center'}} className="text-align-center">{t(AllData.name)}</h2>
              </Col>
            </Card.Header>
            <Card.Body>
              <div className="row">
                <div className=" ">
                  <Tab.Container defaultActiveKey="first">
                    <Tab.Content>
                      <Tab.Pane eventKey="first">
                        <div style={{ display: 'flex', justifyContent: 'center', marginBottom: '20px' }}>
                          <img className="img-fluid" src={AllData.img} alt="" style={{ maxWidth: '250px' }} />
                        </div>
                      </Tab.Pane>
                    </Tab.Content>
                  </Tab.Container>
                </div>
                <div className="col">
                  <div className="text-black">
                    <div dangerouslySetInnerHTML={{ __html: AllData.content }}></div>
                  </div>
                </div>
              </div>
            </Card.Body>
          </Card>
        </div>
      </div>
    </>
  );
};

export default Fruitdetails;
