import webservice from '../../../../services/webservice';
import {createAsyncThunk } from '@reduxjs/toolkit'

export const getParentVaccineName = createAsyncThunk('Vaccine/getParentVaccineName', async (lang) => {
    try {
        const response = await fetch(webservice + 'api/getParentVaccineName', {
            body: JSON.stringify({ lang }),
            method: 'POST',
            headers: {
                'Content-type': 'application/json; charset=UTF-8',
            }
        });
        const responseData = await response.json();
        return responseData;
    } catch (error) {
        console.error('Error:', error);
        throw error;
    }
});

export const getdata = createAsyncThunk('Vaccine/getVaccinelist', async (lang) => {
    try {
        const response = await fetch(webservice + 'api/getVaccinelist', {
            body: JSON.stringify({ lang }),
            method: 'POST',
            headers: {
                'Content-type': 'application/json; charset=UTF-8',
            }
        });

        const responseData = await response.json();
        return responseData;
    } catch (error) {
        console.error('Error:', error);
        throw error; 
    }
});

export const addExistingVaccinelist = createAsyncThunk('Vaccine/addExistingVaccinelist', async (data) => {
    try {
        const response = await fetch(webservice + 'api/addExistingVaccinelist', {
            body: JSON.stringify({ data }),
            method: 'POST',
            headers: {
                'Content-type': 'application/json; charset=UTF-8',
            }
        });

        const responseData = await response.json()
        return responseData;
    } catch (error) {
        console.error('Error:', error);
        throw error;
    }
});

export const deleteVaccinedetails = createAsyncThunk('Vaccine/deleteVaccinedetails', async (data) => {
    try {
        const response = await fetch(webservice + 'api/deleteVaccinelist', {
            method: 'POST',
            body: JSON.stringify({ data }),
            headers: {
                'Content-type': 'application/json; charset=UTF-8',
            }
        });

        const responseData = await response.json();


        return responseData;
    } catch (error) {
        console.error('Error:', error);
        throw error; 
    }
});