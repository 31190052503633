import React, { useState, Fragment, useEffect } from "react";
import { Link } from "react-router-dom";
import { getlanguage, getvegdata, Deletevegdata, postEdidedvegdata, postvegdata } from "./store/index";
import { useDispatch } from "react-redux";
import { useTranslation } from 'react-i18next';
import Breadcrumbs from '../../breadcrumbs';
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
  FormGroup,
  Label,
  Input
} from "reactstrap";
import Swal from "sweetalert2";

const Veg = () => {
  const [imageData, setImageData] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [lang, setLanguage] = useState("");
  const [modal, setModal] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false); // State for edit mode
  const [selectedVeg, setSelectedVeg] = useState(null); // State to hold selected vegetable details
  const [vegName, setVegName] = useState("");
  const [vegContent, setVegContent] = useState("");
  const [vegImage, setVegImage] = useState(null);

  const dispatch = useDispatch();
  const { t } = useTranslation();

  useEffect(() => {
    const id = localStorage.id;
    if (id !== "undefined") {
      dispatch(getlanguage(id)).then((res) => {
        setLanguage(res.payload.language);
        dispatch(getvegdata(res.payload.language)).then((response) => {
          setImageData(response.payload.data);
        });
      });
    }
  }, [dispatch]);

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const filteredVegetables = imageData.filter((item) => {
    return item.name.toLowerCase().includes(searchTerm.toLowerCase());
  });

  // Function to handle delete
  const handleDelete = (id) => {
    Swal.fire({
      title: 'Are you sure?',
      text: "This action cannot be undone.",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result.isConfirmed) {
        const data = { id, lang };
        dispatch(Deletevegdata(data))
          .then((response) => {
            if (response.payload && response.payload.response === 'S') {
              dispatch(getvegdata(lang)).then((response) => {
                if (response.payload) {
                  setImageData(response.payload.data);
                  Swal.fire(
                    'Deleted!',
                    'Your vegetable has been deleted.',
                    'success'
                  );
                } else {
                  console.error('Error fetching vegetable data:', response);
                }
              }).catch(error => {
                console.error('Error fetching vegetable data:', error);
              });
            } else {
              Swal.fire(
                'Error!',
                'There was a problem deleting the vegetable.',
                'error'
              );
              console.error('Error deleting vegetable:', response);
            }
          })
          .catch(error => {
            Swal.fire(
              'Error!',
              'There was a problem deleting the vegetable.',
              'error'
            );
            console.error('Error deleting vegetable:', error);
          });
      }
    });
  };

  const handleEdit = (id) => {
    const veg = imageData.find((item) => item._id === id);
    if (veg) {
      setSelectedVeg(veg);
      setVegName(veg.name);
      setVegContent(veg.content);
      setVegImage(veg.img || null);
      setIsEditMode(true);
      setModal(true);
    }
  };

  const handleSave = () => {
    if (!vegName || !vegContent && vegContent ==='' || !vegImage) {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Please fill in all fields.'
      });
      return;
    }

    const formData = new FormData();
    formData.append("id", selectedVeg._id);
    formData.append("name", vegName);
    formData.append("content", vegContent);
    formData.append("lang", lang);
    if (vegImage) formData.append("img", vegImage);

    dispatch(postEdidedvegdata(formData))
    .then((response) => {

        if (response.payload.response === 'S') {
          dispatch(getvegdata(lang)).then((response) => {
            setImageData(response.payload.data);
          });
          setModal(false);
          setIsEditMode(false);
          Swal.fire({
            icon: 'success',
            title: 'Success!',
            text: 'Vegetable details have been saved successfully.'
          });
        } else {
          Swal.fire({
            icon: 'else',
            title: 'post aaga mattudhu daa...',
            text: 'Error saving vegetable.'
          });
        }
      })
      .catch(error => {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'Error saving vegetable.'
        });
      });
  };

  const handleAddVegetable = () => {

    // if (!vegName || !vegContent || !vegImage) {
    //   Swal.fire({
    //     icon: 'error',
    //     title: 'Oops...',
    //     text: 'Please fill in all fields.'
    //   });
    //   return;
    // }

    const formData = new FormData();
    formData.append("img", vegImage);
    formData.append("content", vegContent);
    formData.append("name", vegName);
    formData.append("lang", lang);

    dispatch(postvegdata(formData))
      .then((response) => {
        if (response.payload.response === 'S') {
          // Fetch the updated vegetable data
          dispatch(getvegdata(lang)).then((response) => {
            setImageData(response.payload.data);
          });

          // Display success message
          Swal.fire({
            icon: 'success',
            title: 'Success!',
            text: 'Vegetable details have been added successfully.'
          });

          // Close the modal
          setModal(false);
          // Clear all form data
          setVegName("");
          setVegContent("");
          setVegImage(null);
        } else {
          Swal.fire({
            title: 'post aagala'
          })
        }
      })
      .catch((error) => {
        // Display error message
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'Error adding vegetable.'
        });
        console.error('Error adding vegetable:', error);
      });
  };

  const toggleModal = () => {
    setModal(!modal);
    if (modal) {
      setIsEditMode(false); // Reset edit mode when closing the modal
      setSelectedVeg(null);
      setVegName("");
      setVegContent("");
      setVegImage(null);
    }
  };

  return (
    <Fragment>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <div style={{ width: '75%' }}>
          <Breadcrumbs title={t('Vegetables')} data={[{ title: t('Vegetables') }]} />
        </div>
        <div>
          <Button style={{ width: '100%' }} color="primary" onClick={() => {
            setIsEditMode(false); // Ensure we're not in edit mode when adding a new vegetable
            setModal(true); // Open the modal for adding new vegetable
          }}>
            Add Vegetable
          </Button>
        </div>
      </div>

      <div className="shopping-cart mb-3">
        <input
          type="text"
          placeholder="Search Vegetable Name..."
          value={searchTerm}
          onChange={handleSearchChange}
          className="form-control text-black"
        />
      </div>
      <div>
        <table className="table table-bordered">
          <thead>
            <tr>
              <th style={{ backgroundColor: 'white', textAlign: 'center' }}>{t('Name')}</th>
              <th style={{ backgroundColor: 'white', textAlign: 'center' }}>{t('Actions')}</th>
            </tr>
          </thead>
          <tbody>
            {filteredVegetables.map((item, index) => (
              <tr key={index}>
                <td style={{ textAlign: 'center' }}>{t(item.name)}</td>
                <td>
                  <div style={{ justifyContent: 'space-around' }} className="d-flex">
                    <Link to={`/veg-details/${item._id}`}>
                      <svg style={{ color: 'green' }} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-eye"><path d="M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z"></path><circle cx="12" cy="12" r="3"></circle></svg>
                    </Link>
                    <Button color="primary" className="shadow btn-xs sharp me-1" onClick={() => handleEdit(item._id)}>
                      <i className="fas fa-pen"></i>
                    </Button>
                    <Button color="danger" className="shadow btn-xs sharp" onClick={() => handleDelete(item._id)}>
                      <i className="fa fa-trash"></i>
                    </Button>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <Modal isOpen={modal} size="lg" toggle={toggleModal}>
        <ModalHeader toggle={toggleModal}>{isEditMode ? 'Edit Vegetable' : 'Add Vegetable'}</ModalHeader>
        <ModalBody>
          <Form>
            <FormGroup>
              <Label for="vegName">Name</Label>
              <Input
                type="text"
                name="vegName"
                id="vegName"
                placeholder="Enter Vegetable Name"
                value={vegName}
                onChange={(e) => setVegName(e.target.value)}
              />
            </FormGroup>
            <FormGroup>
              <Label for="vegContent">Content</Label>
              <Input
                type="textarea"
                style={{height:'40vh'}}
                name="vegContent"
                id="vegContent"
                placeholder="Enter Vegetable Content"
                value={vegContent}
                onChange={(e) => setVegContent(e.target.value)}
              />
            </FormGroup>
            <FormGroup>
              <Label for="vegImage">Upload Image</Label>
              {vegImage ? (
                <div style={{ position: 'relative' }}>
                  <img
                    src={typeof vegImage === 'string' ? vegImage : URL.createObjectURL(vegImage)}
                    alt="Fruit"
                    style={{ width: '100px', height: '100px' }}
                  />
                  <Button style={{color:'red',backgroundColor:'white',borderColor:'white',position:'absolute',fontSize:'25px'}}  onClick={() => setVegImage(null)} >x</Button>
                </div>
              ) : (
                <div>
                  <Button color="primary" onClick={() => document.getElementById('fruitImageUpload').click()}>+</Button>
                  <Input
                    type="file"
                    id="fruitImageUpload"
                    style={{ display: 'none' }}
                    onChange={(e) => {
                      const file = e.target.files[0];
                      if (file) {
                        const modifiedFileName = file.name.replace(/\s+/g, '_');
                        const modifiedFile = new File([file], modifiedFileName, { type: file.type });
                        setVegImage(modifiedFile);
                      }
                    }}
                  />
                </div>
              )}
            </FormGroup>
          </Form>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={isEditMode ? handleSave : handleAddVegetable}>
            {isEditMode ? 'Save ' : 'Add Vegetable'}
          </Button>
          {/* <Button color="secondary" onClick={toggleModal}>Cancel</Button> */}
        </ModalFooter>
      </Modal>
    </Fragment>
  );
};

export default Veg;
