import React, { useState, useEffect, useReducer } from 'react';
import Modal from 'react-bootstrap/Modal';
import { Button, Collapse } from 'react-bootstrap';
import { Link } from "react-router-dom";
import { MenuList } from './Menu';
import { AdminMenuList } from './adminMenu';
import { useScrollPosition } from "@n8tb1t/use-scroll-position";
import { useTranslation } from 'react-i18next';
import webservice from '../../../services/webservice';
// import { userdataflag } from "./store";
import { useDispatch } from 'react-redux';
import { AesDecrypt } from '../../../services/crypto';
import { navtoggle } from '../../../store/actions/AuthActions';

const reducer = (previousState, updatedState) => ({
  ...previousState,
  ...updatedState,
});

const initialState = {
  active: "",
  activeSubmenu: "",
}

const SideBar = () => {
  const { t } = useTranslation();
  const [state, setState] = useReducer(reducer, initialState);
  const [userDataFlag, setUserDataFlag] = useState('');
  const [showModal, setShowModal] = useState(false);
  const dispatch = useDispatch();

  const handlecheckclick = () => {
    // Dispatch action to fetch data
    // dispatch(userdataflag(localStorage.id))
    //   .then((res) => {
    //     if (res.payload.response === 'S') {
    //       const data1 = AesDecrypt(res.payload.data);
    //       if (data1.length !== 0) {
    //         setUserDataFlag('data1');
    //       }
    //     }
    //   })
    //   .catch((error) => {
    //     console.error('Error fetching profile:', error);
    //     // Handle error
    //   });
  };

  // useEffect(() => {
  //   // Initial fetch on component mount
  //   const _id = localStorage.id;
  //   dispatch(userdataflag(_id))
  //     .then((res) => {
  //       if (res.payload.response) {
  //         const data1 = AesDecrypt(res.payload.data);
  //         if (data1.length !== 0) {
  //           setUserDataFlag(data1);
  //         }
  //       }
  //     })
  //     .catch((error) => {
  //       console.error('Error fetching profile:', error);
  //       // Handle error
  //     });
  // }, [dispatch]);

  const handleMenuActive = (status) => {
    setState({ active: status });
    if (state.active === status) {
      setState({ active: "" });
    }

  };

  const handleSubmenuActive = (status) => {
    setState({ activeSubmenu: status });
    if (state.activeSubmenu === status) {
      setState({ activeSubmenu: "" });
    }

  };


  const handleMenuClickresaluation = () => {
    if (window.innerWidth < 780) {
      dispatch(navtoggle())
    }
  }

  const handleMenuClick = () => {
    setShowModal(true);
  };

  // For scroll
  const [hideOnScroll, setHideOnScroll] = useState(true);
  useScrollPosition(
    ({ prevPos, currPos }) => {
      const isShow = currPos.y > prevPos.y;
      if (isShow !== hideOnScroll) setHideOnScroll(isShow);
    },
    [hideOnScroll]
  );

  // Path
  let path = window.location.pathname.split("/").pop();

  useEffect(() => {
    MenuList.forEach((data) => {
      data.content?.forEach((item) => {
        if (path === item.to) {
          setState({ active: data.title });
        }
        item.content?.forEach(ele => {
          if (path === ele.to) {
            setState({ activeSubmenu: item.title, active: data.title });
          }
        });
      });
    });
  }, [path]);

  const usertype = localStorage.getItem('usertype');


  return (
    <div className="deznav">
      <div className="deznav-scroll">
        <ul className="metismenu" id="menu">
          {usertype === 'SuperAdmin' ? (
            AdminMenuList.map((data, index) => (
              <li
                className={`has-menu ${state.active === data.title ? 'mm-active' : ''}${data.to === path ? 'mm-active' : ''}`}
                key={index}
                onClick={handlecheckclick}
              >
                {data.content && data.content.length > 0 ? (
                  <>
                    <Link to="#" className="has-arrow ai-icon" onClick={() => handleMenuActive(data.title)}>
                      {data.iconStyle}{" "}
                      <span className="nav-text">
                        {t(data.title)}
                        {data.update && (
                          <span className="badge badge-xs style-1 badge-danger ms-2">
                            {data.update}
                          </span>
                        )}
                      </span>
                    </Link>
                    <Collapse in={state.active === data.title}>
                      <ul className={`mm-collapse ${state.active === data.title ? "mm-show" : ""}`}>
                        {data.content.map((subData, subIndex) => (
                          <li
                            key={subIndex}
                            className={`${state.activeSubmenu === subData.title ? "mm-active" : ""}${subData.to === path ? 'mm-active' : ''}`}
                          >
                            {subData.content && subData.content.length > 0 ? (
                              <>
                                <Link to={subData.to} className={subData.hasMenu ? 'has-arrow' : ''} onClick={() => handleSubmenuActive(subData.title)}>
                                  {t(subData.title)}
                                </Link>
                                <Collapse in={state.activeSubmenu === subData.title}>
                                  <ul className="mm-collapse mm-show">
                                    {subData.content.map((subSubData, subSubIndex) => (
                                      <li key={subSubIndex}>
                                        <Link className={`${path === subSubData.to ? "mm-active" : ""}`} to={subSubData.to}>
                                          {subSubData.title}
                                        </Link>
                                      </li>
                                    ))}
                                  </ul>
                                </Collapse>
                              </>
                            ) : (
                              <Link to={subData.to} className={`${subData.to === path ? 'mm-active' : ''}`}>
                                {t(subData.title)}
                              </Link>
                            )}
                          </li>
                        ))}
                      </ul>
                    </Collapse>
                  </>
                ) : (
                  <Link to={data.to} className={`${data.to === path ? 'mm-active' : ''}`}>
                    {t(data.title)}
                  </Link>
                )}
              </li>
            ))
          ) : (
            MenuList.map((data, index) => (
              <li
                className={`has-menu ${state.active === data.title ? 'mm-active' : ''}${data.to === path ? 'mm-active' : ''}`}
                key={index}
                onClick={handlecheckclick}
              >
                {data.content && data.content.length > 0 ? (
                  <>
                    <Link to="#" className="has-arrow ai-icon" onClick={() => handleMenuActive(data.title)}>
                      {data.iconStyle}{" "}
                      <span className="nav-text">
                        {t(data.title)}
                        {data.update && (
                          <span className="badge badge-xs style-1 badge-danger ms-2">
                            {data.update}
                          </span>
                        )}
                      </span>
                    </Link>
                    <Collapse in={state.active === data.title}>
                      <ul className={`mm-collapse ${state.active === data.title ? "mm-show" : ""}`}>
                        {data.content.map((subData, subIndex) => (
                          <li
                            key={subIndex}
                            className={`${state.activeSubmenu === subData.title ? "mm-active" : ""}${subData.to === path ? 'mm-active' : ''}`}
                          >
                            {subData.content && subData.content.length > 0 ? (
                              <>
                                <Link to={subData.to} className={subData.hasMenu ? 'has-arrow' : ''} onClick={() => handleSubmenuActive(subData.title)}>
                                  {t(subData.title)}
                                </Link>
                                <Collapse in={state.activeSubmenu === subData.title}>
                                  <ul className="mm-collapse mm-show">
                                    {subData.content.map((subSubData, subSubIndex) => (
                                      <li key={subSubIndex}>
                                        <Link className={`${path === subSubData.to ? "mm-active" : ""}`} to={subSubData.to}>
                                          {subSubData.title}
                                        </Link>
                                      </li>
                                    ))}
                                  </ul>
                                </Collapse>
                              </>
                            ) : (
                              <Link to={subData.to} className={`${subData.to === path ? 'mm-active' : ''}`}>
                                {t(subData.title)}
                              </Link>
                            )}
                          </li>
                        ))}
                      </ul>
                    </Collapse>
                  </>
                ) : (
                  <Link to={data.to} className={`${data.to === path ? 'mm-active' : ''}`}>
                    {t(data.title)}
                  </Link>
                )}
              </li>
            ))
          )}
        </ul>
      </div>

      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>{t("Sanjeevani BOT:")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div style={{ marginLeft: '5%' }}>
            {t("You need to complete your profile details to access this menu")}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Link to={'/Demography'}>
            <div>
              <Button style={{ width: '100%' }} onClick={() => setShowModal(false)}>Click Here</Button>
            </div>
          </Link>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default SideBar;
