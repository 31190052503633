import webservice from '../../../../services/webservice';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { AesEncrypt } from '../../../../services/crypto';


// Get Recent Moods
export const RecentMood = createAsyncThunk('Mood/RecentMood', async (data) => {
    const Encparams = AesEncrypt(data)

    const response = await fetch(webservice + 'api/getRecentMood', {
        method: 'POST',
        body: JSON.stringify({ Encparams }),
        headers: {
            'Content-type': 'application/json; charset=UTF-8',
        }
    })
    const moodData = await response.json();
    return moodData;
})



// Add Mood Monitor
export const AddMood = createAsyncThunk('Mood/AddMood', async (data) => {
    const Encparams = AesEncrypt(data)
    const response = await fetch(webservice + 'api/addMood', {
        method: 'POST',
        body: JSON.stringify({ Encparams }),
        headers: {
            'Content-type': 'application/json; charset=UTF-8',
        }
    })
    const moodData = await response.json();
    return moodData;
})



// export const getMentalhealth = createAsyncThunk('AppMenu/getProfileData', async (data) => {

//     const requestData = {
//         userId: data, // Pass the userId in the request body
//     };
//     const Encparams = AesEncrypt((requestData));
//     try {
//         const response = await fetch(webservice + 'api/getMentalhealth', {
//             method: 'POST',
//             body: JSON.stringify({ Encparams }),
//             headers: {
//                 'Content-type': 'application/json; charset=UTF-8',
//             }
//         })
//         const responseData = await response.json();
//         return responseData;
//     } catch (err) {
//         console.error(err);
//         throw err;
//     }
// })


export const Adddepression = createAsyncThunk('Mood/AddMood', async (data) => {

    const Encparams = AesEncrypt(data)
    const response = await fetch(webservice + 'api/addMentalhealth', {
        method: 'POST',
        body: JSON.stringify({ Encparams }),
        headers: {
            'Content-type': 'application/json; charset=UTF-8',
        }
    })
    const moodData = await response.json();
    return moodData;
})


export const AddAnxiety = createAsyncThunk('Mood/AddMood', async (data) => {

    const Encparams = AesEncrypt(data)
    const response = await fetch(webservice + 'api/addMentalhealth', {
        method: 'POST',
        body: JSON.stringify({ Encparams }),
        headers: {
            'Content-type': 'application/json; charset=UTF-8',
        }
    })
    const moodData = await response.json();
    return moodData;
})

export const AddStress = createAsyncThunk('Mood/AddMood', async (data) => {

    const Encparams = AesEncrypt(data)
    const response = await fetch(webservice + 'api/addMentalhealth', {
        method: 'POST',
        body: JSON.stringify({ Encparams }),
        headers: {
            'Content-type': 'application/json; charset=UTF-8',
        }
    })
    const moodData = await response.json();
    return moodData;
})

export const TrackUserMood = createAsyncThunk('Mood/AddMood', async (data) => {

    const Encparams = AesEncrypt(data)

    const response = await fetch(webservice + 'api/checkMoodTracking', {
        method: 'POST',
        body: JSON.stringify({ userId: Encparams }),
        headers: {
            'Content-type': 'application/json; charset=UTF-8',
        }
    })
    const TrackedMoodData = await response.json();

    return TrackedMoodData;
})


const userID = localStorage.getItem('id')

// DeleteMood Mood Monitor
export const DeleteMood = createAsyncThunk('Mood/DeleteMood', async (id) => {
    const Encparams = AesEncrypt({ _id: id, userID: userID }); // Assuming _id is the key for the ID
    const response = await fetch(webservice + 'api/deleteRecentMood', {
        method: 'POST',
        body: JSON.stringify({ Encparams }),
        headers: {
            'Content-type': 'application/json; charset=UTF-8',
        }
    });
    const moodData = await response.json();
    return moodData;
});



export const MoodMonitorSlice = createSlice({
    name: 'mood_monitor',
    initialState: {
        data: [],
        total: 0,
        params: {},
        allData: []
    },
    reducers: {


    },
    extraReducers: builder => {
        builder.addCase(AddMood.fulfilled, (state, action) => {
            state.data = action.payload.data
            state.allData = action.payload.allData
            state.total = action.payload.totalPages
            state.params = action.payload.params
            state.no_Data = action.payload.no_Data
        })
    }
})


