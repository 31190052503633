import React, { Fragment, useEffect, useState } from "react";
import { useDispatch } from 'react-redux';
import Select from 'react-select';
import { Card, Table, Button, Modal, CardBody, Row } from "react-bootstrap";
import { diabetesDelete, diabetesEdit, getAllData, postDiabetesData } from "./store";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Badge, Col, Input, Label, UncontrolledTooltip } from "reactstrap";
import "./diabetes.css"
import { useTranslation } from 'react-i18next'
import { Link } from "react-router-dom";
import classnames from "classnames";
import webservice from '../../../services/webservice';



// import { selectThemeColors } from "@utils";
// import { Controller } from "react-hook-form";
import { useForm, Controller } from "react-hook-form";
import ReactSelect from "react-select";
import moment from "moment";
const DataTable = ({ alldata, handleReload }) => {
  const [editingIndex, setEditingIndex] = useState(null);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deleteIndex, setDeleteIndex] = useState(null);
  const [editedData, setEditedData] = useState({});
  const [errors, setErrors] = useState({});
  const { t } = useTranslation();
  const [basicModal, setBasicModal] = useState(false);
  const [sugarLevel, setSugarLevel] = useState('');
  const [sugarType, setSugarType] = useState('');
  const defaultDate = moment(new Date()).format('MM-DD-YYYY hh.mm a')
  const [dateTime, setDateTime] = useState(new Date());
  const [meal, setActiveTab] = useState('');
  const [age, setAge] = useState('');
  const [bloodSugarTooltipContent, setBloodSugarTooltipContent] = useState('');

  const sugerTypeOptions = [
    { value: 'HBA1C test', label: t('HBA1C test') },
    { value: 'Fasting blood sugar test', label: t('Fasting test') },
    { value: 'Random blood sugar test', label: t('Random test') },
    { value: 'Glucose tolerance test', label: t('Glucose test') }
  ];



  const {
    control,
    setValue,
    formState: { },
    reset,
  } = useForm();

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      cursor: 'pointer',
      color: 'black',
      '&:hover': {
        // backgroundColor: '#c7dbf9',
        backgroundColor: '#d6e0ef',
      },
    }),
    singleValue: (provided) => ({
      ...provided,
      backgroundColor: 'white',
      color: 'black',
    }),
  };




  const handleSugerTypeChange = (selectedOption) => {
    setSugarType(selectedOption.value)
  }
  const handleEditSugerTypeChange = (selectedOption) => {
    setSugarType(selectedOption.value)
  }

  const dispatch = useDispatch();

  const handleEdit = (index) => {
    setEditingIndex(index);
    setEditedData({ ...alldata[index] });
  };

  const handleDelete = (index) => {
    setDeleteIndex(index);
    setShowDeleteModal(true);
  };

  const handleConfirmDelete = (id) => {
    setShowDeleteModal(false);
    dispatch(diabetesDelete(id)).then((response) => {
      handleReload()

    }); // Pass the ID to the deleteBloodPressureData action
  };

  const handleCloseDeleteModal = () => {
    setShowDeleteModal(false);
  };

  const handleSave = (index) => {
    const { _id, ...editedFields } = editedData;

    dispatch(diabetesEdit({ _id, ...editedFields })).then((response) => {
      handleReload()
    }); // Send only the edited fields to the onSave callback
    setEditingIndex(null);
  };

  const handleInputChange = (value, key) => {
    setEditedData(prevData => ({
      ...prevData,
      [key]: value
    }));
  };

  const handleDateChange = (date, key) => {
    // Extract the time from the existing `testDate`
    const existingTime = moment(editedData[key]).format('hh:mm a');

    // Combine the selected date with the existing time if time not selected
    const updatedDate = moment(date).format('DD-MM-yyyy') + ' ' + existingTime;

    // If time is also selected, update the time part
    const formattedDate = moment(date).format('DD-MM-yyyy hh:mm a');


    // Update the state with the newly formatted date
    setEditedData(prevData => ({
      ...prevData,
      [key]: formattedDate.includes('Invalid date') ? updatedDate : formattedDate,
    }));
  };


  const handlediscard = (index) => {
    setEditingIndex(null);
  };


  const handleTabChange = (tabName) => {
    setActiveTab(tabName);
  };

  const handleDateTimeChange = (date) => {
    if (date > new Date()) {
      setErrors({ date: 'Please select a date and time in the past or present' });
    } else {
      setErrors({});
      setDateTime(date);
    }
  };

  const handleSugarChange = (e) => {
    const error = {};
    error.sugarLevel = t('')
    if (/^\d{0,3}$/.test(e.target.value)) {
      setErrors(error)
      setSugarLevel(e.target.value);
    }
  };

  // const date = new Date(dateTime);
  // const day = date.getDate().toString().padStart(2, "0");
  // const month = (date.getMonth() + 1).toString().padStart(2, "0");
  // const year = date.getFullYear();
  // const testDate = day + "-" + month + "-" + year;
  const testDate = moment(dateTime).format('MM-DD-YYYY hh:mm a')

  useEffect(() => {
    const fetchData = async () => {
      try {
        const userID = localStorage.getItem('id');
        const response = await fetch(webservice + 'api/bmiValue', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({ userID: userID })
        });
        
        const data = await response.json();
        const dob = data.user.dob;
        // Format dob into a valid date string
        const dobParts = dob.split('-');
        const formattedDOB = dobParts[1] + '-' + dobParts[0] + '-' + dobParts[2];
        const birthDate = new Date(formattedDOB);
        // Calculate age from date of birth
        const today = new Date();
        let age = today.getFullYear() - birthDate.getFullYear();
        const monthDiff = today.getMonth() - birthDate.getMonth();
        if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < birthDate.getDate())) {
          age--;
        }

        // Set the age using setAge
        if (!isNaN(age)) {
          setAge(age);
        } else {
          console.error('Invalid age:', age);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    const intervalId = setInterval(fetchData, 1000);
    return () => clearInterval(intervalId);
  }, []);



  const handleBloodSugarInfo = () => {
    let suggestion = '';
    const age1 = age;

    if (age1 >= 1 && age1 <= 12) {
      suggestion = 'Your blood sugar level should be between 70 mg/dL to 140 mg/dL (milligrams per deciliter) when fasting.';
    } else if (age1 >= 13 && age1 <= 19) {
      suggestion = 'Your blood sugar level should be between 70 mg/dL to 150 mg/dL when fasting.';
    } else if (age1 >= 20 && age1 <= 26) {
      suggestion = 'Your blood sugar level should be between 74 mg/dL to 162 mg/dL when fasting.';
    } else if (age1 >= 27 && age1 <= 40) {
      suggestion = 'Your blood sugar level should be between 78 mg/dL to 166 mg/dL when fasting.';
    } else if (age1 >= 41 && age1 <= 59) {
      suggestion = 'Your blood sugar level should be between 79 mg/dL to 166 mg/dL when fasting.';
    } else if (age1 >= 60 && age1 <= 64) {
      suggestion = 'Your blood sugar level should be between 79 mg/dL to 164 mg/dL when fasting.';
    } else {
      suggestion = 'Blood sugar level range for this age group is not available.';
    }

    setBloodSugarTooltipContent(suggestion);
  };
  const getBloodSugarLevelStatus = (sugarLevel) => {
    let errors = {};
    if (sugarLevel === '') {
      return '';
    }
    if (sugarLevel >= 126) {
      return 'Diabetes';
    } else if (sugarLevel >= 100 && sugarLevel <= 125) {
      return 'Prediabetes';
    } else {
      return 'Normal';
    }
  };

  const getBloodSugarLevelColor = (sugarLevel) => {
    const sugarLevelStatus = getBloodSugarLevelStatus(sugarLevel);
    if (sugarLevelStatus === 'Diabetes') {
      return 'red';
    } else if (sugarLevelStatus === 'Prediabetes') {
      return '#c39e19';
    } else if (sugarLevelStatus === 'Normal') {
      return 'green';
    } else {
      return 'black'; // Default color
    }
  };



  const userId = localStorage.id


  const handleSubmit = async (e) => {
    e.preventDefault();

    // Check for empty fields
    let errors = {};

    if (!sugarLevel) {
      errors.sugarLevel = t('Blood Sugar Level is required');
    }
    if (!sugarType) {
      errors.sugarType = t('Test Type is required');
    }
    if (meal === '') {
      errors.meal = t('Meal type is required');
    }

    if (Object.keys(errors).length === 0) {
      // Dispatch the action and await its completion
      dispatch(postDiabetesData({ sugarLevel, sugarType, testDate, meal, userId })).then((response) => {
        handleReload();
        setBasicModal(false);
        setSugarLevel('');
        setSugarType('');
        setActiveTab('');
        setDateTime(new Date());
        setErrors('');

      });
    } else {
      setErrors(errors);
    }
  };

  const handleClose = async (e) => {
    setBasicModal(false);
    setErrors('');

  }

  const CustomInput = React.forwardRef(({ value, onClick }, ref) => (
    <input
      ref={ref}
      value={value}
      onClick={onClick}
      readOnly
      className="input-field"
    />
  ));
  var testvariable = false
  return (
    <>
      <div className="bpsugar-responsive-add-button"  >
        <Button onClick={() => setBasicModal(true)} className="circle-button_bp" color="primary">+</Button>
      </div>
      <Fragment>
        <div style={{ position: 'relative' }}>
          <Table style={{ width: '98%', marginLeft: '1%',marginRight:'1%'}} responsive striped bordered hover >
            <thead className="text-wrap-responsive">
              <tr >
                <th>{t('No')}</th>
                <th>{t('Date')}</th>
                <th>{t('Sugar Level')}</th>
                <th>{t('Sugar Type')}</th>
                <th>{t('Meal')}</th>
                <th>{t('Edit')}</th>
                {editingIndex !== null && (
                  <th>{t('Discard')}</th>
                )}
                {editingIndex === null && (
                  <th>{t('Delete')}</th>
                )}
              </tr>
            </thead>

            <tbody className="text-wrap-responsive">
              {Array.isArray(alldata) && alldata.length > 0 ? (
                alldata.map((data, index) => (
                  <tr key={index} className="text-black">
                    <td>{index + 1}</td>
                    <td>
                      {editingIndex === index ? (
                        <div>
                          <DatePicker
                            onChange={(date) => handleDateChange(date, 'testDate')}
                            isClearable={false}
                            showTimeSelect
                            className="input-field"
                            maxDate={new Date()}
                            selected={new Date(data.testDate)} // Convert string to Date object
                            dateFormat="dd-MM-yyyy hh:mm a"
                            customInput={<CustomInput />}
                          />

                        </div>
                      ) : (
                        data.testDate
                      )}
                    </td>
                    <td>
                      {editingIndex === index ? (
                        <input
                          className="input-field"
                          type="text"
                          value={editedData.sugarLevel}
                          onChange={(e) => {
                            if (/^\d{0,3}$/.test(e.target.value)) {
                              handleInputChange(e.target.value, 'sugarLevel');
                            }
                          }}
                        />
                      ) : (
                        data.sugarLevel
                      )}
                    </td>
                    <td>
                      {editingIndex === index ? (
                        <Select
                          className="input-field"
                          defaultValue={{
                            value: editedData.sugarType,
                            label: editedData.sugarType ? t(editedData.sugarType) : ''
                          }}
                          onChange={(selectedOption) => handleInputChange(selectedOption.value, 'sugarType')}
                          options={[
                            { value: 'HBA1C test', label: t('HBA1C test') },
                            { value: 'Fasting blood sugar test', label: t('Fasting test') },
                            { value: 'Random blood sugar test', label: t('Random test') },
                            { value: 'Glucose tolerance test', label: t('Glucose test') }
                          ]}
                          styles={{
                            menu: (provided) => ({
                              ...provided,
                              zIndex: 9999, // Ensure the dropdown menu appears above other elements
                              opacity: 1,   // Ensure full opacity
                            }),
                            control: (provided) => ({
                              ...provided,
                              zIndex: 1, // Ensure the control appears above other elements if needed
                            }),
                            menuPortal: (base) => ({
                              ...base,
                              zIndex: 9999, // Ensure the dropdown menu portal appears above other elements
                            }),
                          }}
                          menuPortalTarget={document.body} // Ensure the dropdown menu renders inside the body to avoid clipping
                        />
                      ) : (
                        data.sugarType
                      )}
                    </td>
                    <td>
                      {editingIndex === index ? (
                        <Select
                          className="input-field"
                          defaultValue={{
                            value: editedData.meal,
                            label: editedData.meal ? t(editedData.meal) : ''
                          }}
                          onChange={(selectedOption) => handleInputChange(selectedOption.value, 'meal')}
                          options={[
                            { value: 'Before Meal', label: t('Before Meal') },
                            { value: 'After Meal', label: t('After meal') }
                          ]}
                          styles={{
                            menu: (provided) => ({
                              ...provided,
                              zIndex: 9999, // Ensure the dropdown menu appears above other elements
                              opacity: 1,   // Ensure full opacity
                            }),
                            control: (provided) => ({
                              ...provided,
                              zIndex: 1, // Ensure the control appears above other elements if needed
                            }),
                            menuPortal: (base) => ({
                              ...base,
                              zIndex: 9999, // Ensure the dropdown menu portal appears above other elements
                            }),
                          }}
                          menuPortalTarget={document.body} // Ensure the dropdown menu renders inside the body to avoid clipping
                        />
                      ) : (
                        data.meal
                      )}
                    </td>
                    <td>
                      {editingIndex === index ? (
                        <Badge color="success" onClick={() => handleSave(index)}>
                          {t('Save')}
                        </Badge>
                      ) : (
                        <Badge color="primary" onClick={() => handleEdit(index, data)}>
                          {t('Edit')}
                        </Badge>
                      )}
                    </td>
                    <td>
                      {editingIndex === index ? (
                        <Badge color="danger" onClick={() => handlediscard(index)}>
                          {t('Discard')}
                        </Badge>
                      ) : (
                        <Badge color="danger" onClick={() => handleDelete(index)}>
                          {t('Delete')}
                        </Badge>
                      )}
                    </td>
                  </tr>
                ))
              ) : (
                <p style={{ position: 'relative', left: '160%', top: '20px', textWrap: 'nowrap' }}>
                  No data available to display
                </p>
              )}
            </tbody>
          </Table>
        </div>

        <Modal style={{
          position: 'fixed',
          top: '25%',
        }}
          show={showDeleteModal} onHide={handleCloseDeleteModal}>
          <Modal.Header closeButton>
            <Modal.Title>{t('Delete Confirmation')}</Modal.Title>
          </Modal.Header>
          <Modal.Body>{t('Are you sure you want to delete this record?')}</Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleCloseDeleteModal}>
              {t('Cancel')}
            </Button>
            <Button variant="danger" onClick={() => handleConfirmDelete(alldata[deleteIndex]._id)}>{t('Delete')}</Button>
          </Modal.Footer>
        </Modal>


        <div className="bootstrap-modal">
          <Modal className="fade" show={basicModal} onHide={() => setBasicModal(false)}>
            <Modal.Header>
              <Card.Title>{t('Enter Diabetes Details Here')}</Card.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="form-group row">
                <label className="col-sm-4 col-form-label text-black">
                  {t('Blood Sugar Level (mg/dL) :')}
                </label>
                <div className="col-sm-8">
                  <div style={{ position: 'relative' }}>
                    <Input
                      type="text"
                      className={`form-control${errors.sugarLevel ? ' is-invalid' : ''} text-black`}
                      value={sugarLevel}
                      onChange={(e) => handleSugarChange(e)}
                      required
                    />
                    {errors.sugarLevel && (
                      <div style={{ color: 'red', marginTop: '5px' }}>
                        {errors.sugarLevel}
                      </div>
                    )}
                    <span
                      style={{ position: 'absolute', right: 10, top: 18 }}
                      onMouseEnter={handleBloodSugarInfo}
                    >
                      <i className="fa fa-info-circle" id="bloodSugarInfoIcon">
                        <UncontrolledTooltip target="bloodSugarInfoIcon">
                          {bloodSugarTooltipContent}
                        </UncontrolledTooltip>
                      </i>
                    </span>
                  </div>
                  <div className="Bpsuger_lable">
                    <label style={{ color: getBloodSugarLevelColor(sugarLevel) }}>
                      {getBloodSugarLevelStatus(sugarLevel)}
                    </label>
                  </div>
                </div>
              </div>

              <div className="form-group row" style={{ marginBottom: '8%' }}>
                <label className="col-sm-4 col-form-label text-black">
                  {t('Test Type :')}
                </label>
                <div className="col-sm-8">
                  <Controller
                    id="sugarType"
                    control={control}
                    name="sugarType"
                    render={({ field }) => (
                      <Select
                        options={sugerTypeOptions}
                        classNamePrefix="select"
                        styles={customStyles}
                        className={classnames("react-select", { 'is-invalid': errors.sugarType })}
                        {...field}
                        onChange={handleSugerTypeChange}
                      />
                    )}
                  />

                  {errors.sugarType && (
                    <div className="" style={{ color: 'red', marginTop: '5px' }}>{errors.sugarType}</div>
                  )}
                </div>
              </div>

              <div className="form-group row">
                <label className="col-sm-4 col-form-label text-black">
                  {t('Meal Type :')}
                </label>
                <div className="col-sm-8">
                  <div style={{ display: 'flex', width: '120%' }}>
                    <div className={`tab ${meal === 'Before Meal' ? 'active' : ''}`} onClick={() => handleTabChange('Before Meal')}>
                      {t('Before Meal')}
                    </div>
                    &nbsp;&nbsp;&nbsp;
                    <div className={`tab ${meal === 'After Meal' ? 'active' : ''}`} onClick={() => handleTabChange('After Meal')}>
                      {t('After meal')}
                    </div>
                  </div>
                  {errors.meal && (
                    <div className="" style={{ color: 'red', marginTop: '5px' }}>{errors.meal}</div>
                  )}
                </div>
              </div>

              <div className="form-group row">
                <label className="col-sm-4 col-form-label text-black">
                  {t('Date and Time :')}
                </label>
                <div className="col-sm-8">
                  <DatePicker
                    selected={dateTime}
                    onChange={handleDateTimeChange}
                    showTimeSelect
                    dateFormat="dd-MM-yyyy hh:mm a"
                    required
                    maxDate={new Date()}
                    customInput={<CustomInput />}
                  />
                  {errors.date && (
                    <div className="" style={{ color: 'red', marginTop: '5px' }}>{errors.date}</div>
                  )}
                </div>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Row>
                <Col>
                  <Button
                    variant="primary"
                    className="text-center mt-2 pt-50"
                    style={{ width: "100%" }}
                    onClick={handleSubmit}
                  >
                    {t('Save')}
                  </Button>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Button
                    onClick={handleClose}
                    variant="danger"
                    className="text-center mt-2 pt-50"
                  >
                    {t('Close')}
                  </Button>
                </Col>
              </Row>
            </Modal.Footer>
          </Modal>
        </div>

      </Fragment>

    </>
  );
};

export default DataTable;