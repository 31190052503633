import React, { useState, Fragment, useEffect } from "react";
import { Link } from "react-router-dom";
import { getlanguage, getnutdata, Deletenutdata, Editnutdata, postEdidednutdata, postnutdata } from "./store/index";
import { useDispatch } from "react-redux";
import { useTranslation } from 'react-i18next';
import BreadCrumbs from "../../breadcrumbs";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
  FormGroup,
  Label,
  Input
} from "reactstrap";
import Swal from "sweetalert2";

const Nuts = () => {
  const [imageData, setImageData] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [lang, setLanguage] = useState("");
  const [modal, setModal] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false); // State for edit mode
  const [selectedNut, setSelectedNut] = useState(null); // State to hold selected nut details
  const [nutName, setNutName] = useState("");
  const [nutContent, setNutContent] = useState("");
  const [nutImage, setNutImage] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const dispatch = useDispatch();
  const { t } = useTranslation();

  useEffect(() => {
    const id = localStorage.id;
    if (id !== "undefined") {
      dispatch(getlanguage(id)).then((res) => {
        setLanguage(res.payload.language);
        dispatch(getnutdata(res.payload.language)).then((response) => {
          setImageData(response.payload.data);
        });
      });
    }
  }, [dispatch]);

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const filteredNuts = imageData.filter((item) => {
    return item.name.toLowerCase().includes(searchTerm.toLowerCase());
  });

  // Function to handle delete
  const handleDelete = (id) => {
    Swal.fire({
      title: 'Are you sure?',
      text: "This action cannot be undone.",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result.isConfirmed) {
        const data = { id, lang };
        dispatch(Deletenutdata(data))
          .then((response) => {
            if (response.payload && response.payload.response === 'S') {
              dispatch(getnutdata(lang)).then((response) => {
                if (response.payload) {
                  setImageData(response.payload.data);
                  Swal.fire(
                    'Deleted!',
                    'Your nut has been deleted.',
                    'success'
                  );
                } else {
                  console.error('Error fetching nut data:', response);
                }
              }).catch(error => {
                console.error('Error fetching nut data:', error);
              });
            } else {
              Swal.fire(
                'Error!',
                'There was a problem deleting the nut.',
                'error'
              );
              console.error('Error deleting nut:', response);
            }
          })
          .catch(error => {
            Swal.fire(
              'Error!',
              'There was a problem deleting the nut.',
              'error'
            );
            console.error('Error deleting nut:', error);
          });
      }
    });
  };

  const handleEdit = (id) => {
    const nut = imageData.find((item) => item._id === id);
    if (nut) {
      setSelectedNut(nut);
      setNutName(nut.name);
      setNutContent(nut.content);
      setNutImage(nut.img || null);
      setIsEditMode(true);
      setModal(true);
    }
  };

  const handleSave = () => {
    if (!nutName || !nutContent || !nutImage) {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Please fill in all fields.'
      });
      return;
    }

    const formData = new FormData();
    formData.append("id", selectedNut._id);
    formData.append("name", nutName);
    formData.append("content", nutContent);
    formData.append("lang", lang);
    if (nutImage) formData.append("img", nutImage);

    dispatch(postEdidednutdata(formData))
      .then((response) => {
        if (response.payload.response === 'S') {
          dispatch(getnutdata(lang)).then((response) => {
            setImageData(response.payload.data);
          });
          setModal(false);
          setIsEditMode(false);
          Swal.fire({
            icon: 'success',
            title: 'Success!',
            text: 'Nut details have been saved successfully.'
          });
        } else {
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'Error saving nut.'
          });
        }
      })
      .catch(error => {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'Error saving nut.'
        });
      });
  };

  const handleAddNut = () => {
    if (!nutName || !nutContent || !nutImage) {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Please fill in all fields.'
      });
      return;
    }

    if (isSubmitting) return; // Prevent multiple submissions

    setIsSubmitting(true);

    const formData = new FormData();
    formData.append("img", nutImage);
    formData.append("content", nutContent);
    formData.append("name", nutName);
    formData.append("lang", lang);

    dispatch(postnutdata(formData))
      .then((response) => {
        if (response.payload.response === 'S') {
          // Fetch the updated nut data
          dispatch(getnutdata(lang)).then((response) => {
            setImageData(response.payload.data);
          });

          // Display success message
          Swal.fire({
            icon: 'success',
            title: 'Success!',
            text: 'Nut details have been added successfully.'
          });

          // Close the modal
          setModal(false);
          // Clear all form data
          setNutName("");
          setNutContent("");
          setNutImage(null);
        }
      })
      .catch((error) => {
        // Display error message
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'Error adding nut.'
        });
        console.error('Error adding nut:', error);
      });
  };

  const toggleModal = () => {
    setModal(!modal);
    if (modal) {
      setIsEditMode(false); // Reset edit mode when closing the modal
      setSelectedNut(null);
      setNutName("");
      setNutContent("");
      setNutImage(null);
    }
  };

  return (
    <Fragment>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <div style={{ width: '75%' }}>
          <BreadCrumbs title={t('Nuts')} data={[{ title: t('Nuts') }]} />
        </div>
        <div>
          <Button style={{ width: '100%' }} color="primary" onClick={() => {
            setIsEditMode(false); // Ensure we're not in edit mode when adding a new nut
            setModal(true); // Open the modal for adding new nut
          }}>
            Add Nut
          </Button>
        </div>
      </div>

      <div className="shopping-cart mb-3">
        <input
          type="text"
          placeholder="Search Nut Name..."
          value={searchTerm}
          onChange={handleSearchChange}
          className="form-control text-black"
        />
      </div>
      <div>
        <table className="table table-bordered">
          <thead>
            <tr>
              <th style={{ backgroundColor: 'white', textAlign: 'center' }}>{t('Name')}</th>
              <th style={{ backgroundColor: 'white', textAlign: 'center' }}>{t('Actions')}</th>
            </tr>
          </thead>
          <tbody>
            {filteredNuts.map((item, index) => (
              <tr key={index}>
                <td style={{ textAlign: 'center' }}>{t(item.name)}</td>
                <td>
                  <div style={{ justifyContent: 'space-around' }} className="d-flex">
                    <Link to={`/nut-details/${item._id}`}>
                      <svg style={{ color: 'green' }} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-eye"><path d="M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z"></path><circle cx="12" cy="12" r="3"></circle></svg>
                    </Link>
                    <Button color="primary" className="shadow btn-xs sharp me-1" onClick={() => handleEdit(item._id)}>
                      <i className="fas fa-pen"></i>
                    </Button>
                    <Button color="danger" className="shadow btn-xs sharp" onClick={() => handleDelete(item._id)}>
                      <i className="fa fa-trash"></i>
                    </Button>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {/* Modal for adding/editing nuts */}
      <Modal isOpen={modal} size="lg" toggle={toggleModal}>
        <ModalHeader toggle={toggleModal}>{isEditMode ? 'Edit Nut' : 'Add Nut'}</ModalHeader>
        <ModalBody>
          <Form>
            <FormGroup>
              <Label for="nutName">Name</Label>
              <Input
                type="text"
                name="nutName"
                id="nutName"
                placeholder="Enter Nut Name"
                value={nutName}
                onChange={(e) => setNutName(e.target.value)}
              />
            </FormGroup>
            <FormGroup>
              <Label for="nutContent">Content</Label>
              <Input
                type="textarea"
                style={{height:'40vh'}}
                name="nutContent"
                id="nutContent"
                placeholder="Enter Nut Content"
                value={nutContent}
                onChange={(e) => setNutContent(e.target.value)}
              />
            </FormGroup>
            <FormGroup>
              <Label for="nutImage">Upload Image</Label>
              {nutImage ? (
                <div style={{ position: 'relative' }}>
                  <img
                    src={typeof nutImage === 'string' ? nutImage : URL.createObjectURL(nutImage)}
                    alt="Fruit"
                    style={{ width: '100px', height: '100px' }}
                  />
                  <Button style={{ color: 'red', backgroundColor: 'white', borderColor: 'white', position: 'absolute', fontSize: '25px' }} onClick={() => setNutImage(null)} >x</Button>
                </div>
              ) : (
                <div>
                  <Button color="primary" onClick={() => document.getElementById('fruitImageUpload').click()}>+</Button>
                  <Input
                    type="file"
                    id="fruitImageUpload"
                    style={{ display: 'none' }}
                    onChange={(e) => {
                      const file = e.target.files[0];
                      if (file) {
                        const modifiedFileName = file.name.replace(/\s+/g, '_');
                        const modifiedFile = new File([file], modifiedFileName, { type: file.type });
                        setNutImage(modifiedFile);
                      }
                    }}
                  />
                </div>
              )}
            </FormGroup>
          </Form>
        </ModalBody>
        <ModalFooter>
          <Button
            color="primary"
            onClick={isEditMode ? handleSave : handleAddNut}
            disabled={isSubmitting}
          >
            {isEditMode ? 'Save Changes' : 'Add Nut'}
          </Button>

          {/* <Button color="secondary" onClick={toggleModal}>Cancel</Button> */}
        </ModalFooter>
      </Modal>
    </Fragment>
  );
};

export default Nuts;
