import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import webservice from '../../../../services/webservice'
import { AesEncrypt } from '../../../../services/crypto';
const userId = localStorage.id

export const blooddata = createAsyncThunk('UserType/blooddata', async (userId) => {
    try {
        const response = await fetch(webservice + 'api/blood', {
            method: 'POST', // Change method to GET
            body: JSON.stringify({ userId }), // Pass the entire data object directly

            headers: {
                'Content-type': 'application/json; charset=UTF-8',
            }
        });

        const responseData = await response.json();
        return responseData;
    } catch (error) {
        console.error('Error:', error);
        throw error; // Rethrow the error to be handled by Redux Toolkit
    }
});

export const bloodreceiverdatas = createAsyncThunk('UserType/bloodreceiverdatas', async (userId) => {
    const Encparams1 = {
        userId: userId
    }
    const Encparams = AesEncrypt(Encparams1)
    try {
        const response = await fetch(webservice + 'api/getAllPosts', {
            method: 'POST',
            body: JSON.stringify({ Encparams }),
            headers: {
                'Content-type': 'application/json; charset=UTF-8',
            }
        });
        const responseData = await response.json()
        return responseData
    } catch (error) {
        console.error('Error :', error);
        throw error
    }
});



export const isDonor = createAsyncThunk('UserType/blooddata', async (userId) => {
    try {
        const response = await fetch(webservice + 'api/isDonor', {
            method: 'POST', // Change method to GET
            body: JSON.stringify({ userId }), // Pass the entire data object directly

            headers: {
                'Content-type': 'application/json; charset=UTF-8',
            }
        });

        const responseData = await response.json();
        return responseData;
    } catch (error) {
        console.error('Error:', error);
        throw error; // Rethrow the error to be handled by Redux Toolkit
    }
});


export const bloodetails = createAsyncThunk('UserType/bloodetails', async (id) => {
    try {
        const response = await fetch(webservice + 'api/blood_Details', {
            method: 'POST',
            body: JSON.stringify({ id }), // Pass the entire data object directly
            headers: {
                'Content-type': 'application/json; charset=UTF-8',
            }
        });
        const responseData = await response.json();
        return responseData;
    } catch (error) {
        console.error('Error:', error);
        throw error; // Rethrow the error to be handled by Redux Toolkit
    }
});


export const bloodRequestPost = createAsyncThunk('UserType/CreatePost', async (Encparam) => {

    const Encparams1 = {
        userId: Encparam
    }
    const Encparams = AesEncrypt(Encparams1)
    try {
        const response = await fetch(webservice + 'api/getBloodRequestPost', {
            method: 'POST',
            body: JSON.stringify({ Encparams }), // Pass the entire data object directly
            headers: {
                'Content-type': 'application/json; charset=UTF-8',
            }
        });
        const responseData = await response.json();
        return responseData;
    } catch (error) {
        console.error('Error:', error);
        throw error; // Rethrow the error to be handled by Redux Toolkit
    }
});


export const userAccesptPost = createAsyncThunk('UserType/CreatePost', async (Encparam) => {
    const Encparams1 = {
        senderId: userId,
        receiverId: Encparam.userId,
        postId: Encparam._id,
        acceptStatus: !Encparam.acceptStatus
    }
    const Encparams = AesEncrypt(Encparams1)
    try {
        const response = await fetch(webservice + 'api/updateAcceptStatus', {
            method: 'POST',
            body: JSON.stringify({ Encparams }), // Pass the entire data object directly
            headers: {
                'Content-type': 'application/json; charset=UTF-8',
            }
        });
        const responseData = await response.json();
        return responseData;
    } catch (error) {
        console.error('Error:', error);
        throw error; // Rethrow the error to be handled by Redux Toolkit
    }
});




export const userPostResponce = createAsyncThunk('UserType/CreatePost', async (Encparam) => {
    const Encparams1 = {
        userId: Encparam.userId,
        postId: Encparam._id
    }
    const Encparams = AesEncrypt(Encparams1)
    try {
        const response = await fetch(webservice + 'api/getBloodPostResponse', {
            method: 'POST',
            body: JSON.stringify({ Encparams }), // Pass the entire data object directly
            headers: {
                'Content-type': 'application/json; charset=UTF-8',
            }
        });
        const responseData = await response.json();
        return responseData;
    } catch (error) {
        console.error('Error:', error);
        throw error; // Rethrow the error to be handled by Redux Toolkit
    }
});

export const deleteBloodRequestPost = createAsyncThunk('UserType/CreatePost', async (postId) => {
    const Encparams1 = {
        postId: postId.postId,
        reason: postId.reason
    }
    const Encparams = AesEncrypt(Encparams1)
    try {
        const response = await fetch(webservice + 'api/deleteBloodRequestPost', {
            method: 'POST',
            body: JSON.stringify({ Encparams }), // Pass the entire data object directly
            headers: {
                'Content-type': 'application/json; charset=UTF-8',
            }
        });
        const responseData = await response.json();
        return responseData;
    } catch (error) {
        console.error('Error:', error);
        throw error; // Rethrow the error to be handled by Redux Toolkit
    }
});

export const findAllBloodDonor = createAsyncThunk('UserType/findDonor', async (postId) => {
    const Encparams1 = {
        userId: userId,
        postId: postId
    }
    const Encparams = AesEncrypt(Encparams1)
    try {
        const response = await fetch(webservice + 'api/findBloodDonor', {
            method: 'POST',
            body: JSON.stringify({ Encparams }), // Pass the entire data object directly
            headers: {
                'Content-type': 'application/json; charset=UTF-8',
            }
        });
        const responseData = await response.json();
        return responseData;
    } catch (error) {
        console.error('Error:', error);
        throw error;
    }
});


export const updateNoThanks = createAsyncThunk('UserType/CreatePost', async (userId) => {
    const Encparams1 = {
        id: userId
    }
    const Encparams = AesEncrypt(Encparams1)
    try {
        const response = await fetch(webservice + 'api/noThanks', {
            method: 'POST',
            body: JSON.stringify({ Encparams: Encparams }), // Pass the entire data object directly
            headers: {
                'Content-type': 'application/json; charset=UTF-8',
            }
        });
        const responseData = await response.json();
        return responseData;
    } catch (error) {
        console.error('Error:', error);
        throw error;
    }
});

export const updateComplated = createAsyncThunk('UserType/CreatePost', async (receiver) => {
    const Encparams1 = {
        id: receiver._id,
        postId: receiver.postId,
        receiverId: userId,
        senderId: receiver.userId,
        bloodGroup: receiver.bloodGroup
    }
    const Encparams = AesEncrypt(Encparams1)
    try {
        const response = await fetch(webservice + 'api/completedBloodPost', {
            method: 'POST',
            body: JSON.stringify({ Encparams: Encparams }), // Pass the entire data object directly
            headers: {
                'Content-type': 'application/json; charset=UTF-8',
            }
        });
        const responseData = await response.json();
        return responseData;
    } catch (error) {
        console.error('Error:', error);
        throw error;
    }
});

export const updateRemoveStatus = createAsyncThunk('UserType/CreatePost', async (receiver) => {
    const Encparams1 = {
        id: receiver._id
    }
    const Encparams = AesEncrypt(Encparams1)
    try {
        const response = await fetch(webservice + 'api/removePostResponse', {
            method: 'POST',
            body: JSON.stringify({ Encparams: Encparams }), // Pass the entire data object directly
            headers: {
                'Content-type': 'application/json; charset=UTF-8',
            }
        });
        const responseData = await response.json();
        return responseData;
    } catch (error) {
        console.error('Error:', error);
        throw error;
    }
});

export const getRequestedBloodDonor = createAsyncThunk('UserType/CreatePost', async (receiver) => {
    const Encparams1 = {
        postId: receiver,
        userId: userId
    }
    const Encparams = AesEncrypt(Encparams1)
    try {
        const response = await fetch(webservice + 'api/getRequestedBloodDonor', {
            method: 'POST',
            body: JSON.stringify({ Encparams: Encparams }), // Pass the entire data object directly
            headers: {
                'Content-type': 'application/json; charset=UTF-8',
            }
        });
        const responseData = await response.json();
        return responseData;
    } catch (error) {
        console.error('Error:', error);
        throw error;
    }
});


export const sendTargetDonor = createAsyncThunk('UserType/CreatePost', async (receiver) => {
    const Encparams1 = {
        postId: receiver.postId,
        receiverId: Array.isArray(receiver.receiverId) ? receiver.receiverId : [receiver.receiverId],
        senderId: userId,
        requestStatus: receiver.requestStatus
    }

    const Encparams = AesEncrypt(Encparams1)
    try {
        const response = await fetch(webservice + 'api/requestTargetDonor', {
            method: 'POST',
            body: JSON.stringify({ Encparams: Encparams }), // Pass the entire data object directly
            headers: {
                'Content-type': 'application/json; charset=UTF-8',
            }
        });
        const responseData = await response.json();
        return responseData;
    } catch (error) {
        console.error('Error:', error);
        throw error;
    }
});


export const updateCancelAndRemove = createAsyncThunk('UserType/CreatePost', async (receiver) => {
    const Encparams1 = {
        postId: receiver.postId,
        receiverId: Array.isArray(receiver.receiverId) ? receiver.receiverId : [receiver.receiverId], // Ensure receiverId is an array
        senderId: userId,
        status: receiver.status,
        requestStatus: receiver.requestStatus
    };
    const Encparams = AesEncrypt(Encparams1)
    try {
        const response = await fetch(webservice + 'api/requestTargetDonor', {
            method: 'POST',
            body: JSON.stringify({ Encparams: Encparams }), // Pass the entire data object directly
            headers: {
                'Content-type': 'application/json; charset=UTF-8',
            }
        });
        const responseData = await response.json();
        return responseData;
    } catch (error) {
        console.error('Error:', error);
        throw error;
    }
});


export const getAcceptedBloodPost = createAsyncThunk('UserType/CreatePost', async (receiver) => {
    const Encparams1 = {
        userId: userId
    }
    const Encparams = AesEncrypt(Encparams1)
    try {
        const response = await fetch(webservice + 'api/acceptedBloodPost', {
            method: 'POST',
            body: JSON.stringify({ Encparams: Encparams }), // Pass the entire data object directly
            headers: {
                'Content-type': 'application/json; charset=UTF-8',
            }
        });
        const responseData = await response.json();
        return responseData;
    } catch (error) {
        console.error('Error:', error);
        throw error;
    }
});


export const NotificationForPost = createAsyncThunk('UserType/CreatePost', async (receiver) => {
    const Encparams1 = {
        userId: userId
    }
    const Encparams = AesEncrypt(Encparams1)
    try {
        const response = await fetch(webservice + 'api/getNotifications', {
            method: 'POST',
            body: JSON.stringify({ Encparams: Encparams }), // Pass the entire data object directly
            headers: {
                'Content-type': 'application/json; charset=UTF-8',
            }
        });
        const responseData = await response.json();
        return responseData;
    } catch (error) {
        console.error('Error:', error);
        throw error;
    }
});


export const removeCancelAcceptClick = createAsyncThunk('UserType/CreatePost', async (receiver) => {
    const Encparams1 = {
        id: receiver.id,
        acceptStatus: receiver.acceptStatus,
        rejectedStatus: false,
        status: receiver.status
    }
    const Encparams = AesEncrypt(Encparams1)
    try {
        const response = await fetch(webservice + 'api/updateRequestAccept', {
            method: 'POST',
            body: JSON.stringify({ Encparams: Encparams }), // Pass the entire data object directly
            headers: {
                'Content-type': 'application/json; charset=UTF-8',
            }
        });
        const responseData = await response.json();
        return responseData;
    } catch (error) {
        console.error('Error:', error);
        throw error;
    }
});

export const rejectButtonClick = createAsyncThunk('UserType/CreatePost', async (receiver) => {
    const Encparams1 = {
        id: receiver.id,
        acceptStatus: receiver.acceptStatus,
        rejectedStatus: true,
        status: receiver.status
    }
    const Encparams = AesEncrypt(Encparams1)
    try {
        const response = await fetch(webservice + 'api/updateRequestAccept', {
            method: 'POST',
            body: JSON.stringify({ Encparams: Encparams }), // Pass the entire data object directly
            headers: {
                'Content-type': 'application/json; charset=UTF-8',
            }
        });
        const responseData = await response.json();
        return responseData;
    } catch (error) {
        console.error('Error:', error);
        throw error;
    }
});

export const getDonationHistory = createAsyncThunk('UserType/CreatePost', async (receiver) => {
    const Encparams1 = {
        userId: userId
    }
    const Encparams = AesEncrypt(Encparams1)
    try {
        const response = await fetch(webservice + 'api/getDonatedList', {
            method: 'POST',
            body: JSON.stringify({ Encparams: Encparams }), // Pass the entire data object directly
            headers: {
                'Content-type': 'application/json; charset=UTF-8',
            }
        });
        const responseData = await response.json();
        return responseData;
    } catch (error) {
        console.error('Error:', error);
        throw error;
    }
});


export const getReceivedHistory = createAsyncThunk('UserType/CreatePost', async (receiver) => {
    const Encparams1 = {
        userId: userId
    }
    const Encparams = AesEncrypt(Encparams1)
    try {
        const response = await fetch(webservice + 'api/getReceivedList', {
            method: 'POST',
            body: JSON.stringify({ Encparams: Encparams }), // Pass the entire data object directly
            headers: {
                'Content-type': 'application/json; charset=UTF-8',
            }
        });
        const responseData = await response.json();
        return responseData;
    } catch (error) {
        console.error('Error:', error);
        throw error;
    }
});


