import axios from 'axios';
import swal from "sweetalert";
import {
    loginConfirmedAction,
    Logout,
} from '../store/actions/AuthActions';

import webservice from '../services/webservice';

import { AesEncrypt } from './crypto';

const lang = localStorage.lang

export function signUp(mobile, password) {
    const postData = { mobile, password }
    // //axios call
    const postEncrypt = AesEncrypt(postData)

    return axios.post(
        webservice + `api/sendOtp`,
        { Encparams: postEncrypt },
    );
}


export function checkEmail(email) {
    //axios call
    const data = { email: email }
    const postEncrypt = AesEncrypt(data)
    return axios.post(
        webservice + `api/checkEmail`,
        { Encparams: postEncrypt },
    );
}
export function forgotPassword(email) {
    //axios call

    const postEncrypt = AesEncrypt(email)
    return axios.post(
        webservice + `api/forgotPassword`,
        { Encparams: postEncrypt },
    );
}
export function resetPassword(data) {
    //axios call

    const postEncrypt = AesEncrypt(data)
    return axios.post(
        webservice + `api/resetPassword`,
        { Encparams: postEncrypt },
    );
}

export function otpVerify(mobile, password) {
    //axios call
    const postData = { mobile, password, lang };
    const postEncrypt = AesEncrypt(postData)
    return axios.post(
        webservice + `api/register`,
        { Encparams: postEncrypt },
    );
}

export function login(mobile, password) {
    const postData = { mobile, password };
    const postEncrypt = AesEncrypt(postData)
    return axios.post(
        webservice + `api/AdminLogin`,
        { Encparams: postEncrypt },
    );
}

export function Fulldetails_login(
    name,
    dob,
    gender,
    foodType,
    maritalStatus,
    bloodGroup,
    profession,
    height,
    weight,
    email,
    mobile,
    userId) {
     
    const postData = {
        name,
        dob,
        gender,
        foodType,
        maritalStatus,
        bloodGroup,
        profession,
        height,
        weight,
        email,
        mobile,
        userId
    };
    const postEncrypt = AesEncrypt(postData)
    return axios.post(
        webservice + `api/userData`,
        { Encparams: postEncrypt },
    );
}


export function formatError(errorResponse) {
    switch (errorResponse.error.message) {
        case 'Mobile_Registerd':
            swal("Oops", "Mobile Number already exists", "error");
            break;
        case 'Mobile_Not_Found':
            swal("Oops", "Mobile Number not found", "error", { button: "Try Again!", });
            break;
        case 'INVALID_PASSWORD':
            swal("Oops", "Invalid Password", "error", { button: "Try Again!", });
            break;
        case 'USER_DISABLED':
            return 'User Disabled';
        default:
            return '';
    }
}

export function saveTokenInLocalStorage(tokenDetails) {
    tokenDetails.expireDate = new Date(
        new Date().getTime() + tokenDetails.expiresIn * 1000,
    );
    localStorage.setItem('userDetails', JSON.stringify(tokenDetails));
    localStorage.setItem('id', (tokenDetails._id));
    localStorage.setItem('img', (tokenDetails.img));
    localStorage.setItem('name', (tokenDetails.name));
    localStorage.setItem('parentid', (tokenDetails._id));
    localStorage.setItem('userDataFlag', (tokenDetails.userDataFlag));
    localStorage.setItem('password', (tokenDetails.password));
    localStorage.setItem('dob', (tokenDetails.dob));
    localStorage.setItem('blood', (tokenDetails.bloodGroup));
}

// export function runLogoutTimer(dispatch, timer, navigate) {
//     setTimeout(() => {
//         dispatch(Logout(navigate));
//     }, timer);
// }

export function checkAutoLogin(dispatch, navigate) {
    const tokenDetailsString = localStorage.getItem('userDetails');
    let tokenDetails = '';
    if (!tokenDetailsString) {
        dispatch(Logout(navigate));
        return;
    }

    tokenDetails = JSON.parse(tokenDetailsString);
    let expireDate = new Date(tokenDetails.expireDate);
    let todaysDate = new Date();

    if (todaysDate > expireDate) {
        dispatch(Logout(navigate));
        return;
    }

    dispatch(loginConfirmedAction(tokenDetails));

    // const timer = expireDate.getTime() - todaysDate.getTime();
    // runLogoutTimer(dispatch, timer, navigate);
}